import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { useAppSelector } from '../../states/app/hooks'
import { selectAside } from '../../states/features/Aside/AsideSlice'
import UserMenuLeft from './UserMenuLeft'
import CategoryMenuLeft from '../../views/Category/components/MenuLeft'
import { useMediaQuery } from '@mui/material'

const drawerWidth = 250

export interface menuProps {
    children: React.ReactElement
    openMenuLeft: boolean
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}
const Menu: React.FC<menuProps> = ({ children, openMenuLeft, setOpenMenuLeft }) => {
    const { asideName } = useAppSelector(selectAside)
    const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
    return (
        <Box sx={{ display: 'flex', minHeight: '100%', width: '100%' }}>
            {!matches ? (
                <Drawer
                    sx={{
                        width: drawerWidth,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            borderRight: (theme) => (theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)'),
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    {asideName !== 'Category' ? <UserMenuLeft setOpenMenuLeft={setOpenMenuLeft} /> : <CategoryMenuLeft setOpenMenuLeft={setOpenMenuLeft} />}
                </Drawer>
            ) : (
                <Drawer
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                            borderRight: (theme) => (theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)'),
                        },
                    }}
                    variant="temporary"
                    onClose={() => setOpenMenuLeft((prev) => !prev)}
                    anchor="left"
                    open={openMenuLeft}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    {asideName !== 'Category' ? <UserMenuLeft setOpenMenuLeft={setOpenMenuLeft} /> : <CategoryMenuLeft setOpenMenuLeft={setOpenMenuLeft} />}
                </Drawer>
            )}
            <Box component="main" sx={{ width: `calc(100% - ${drawerWidth}px)`, minHeight: '100%', flexGrow: 1, px: 0.5, display: 'flex', flexDirection: 'column' }}>
                {children}
            </Box>
        </Box>
    )
}

export default Menu
