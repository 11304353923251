import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import LazyImage from '../LazyImage'
import { blue } from '@mui/material/colors'

const StyledBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
`

const PageLoader: React.FC = () => {
    return (
        <StyledBox>
            <LazyImage src="/android-chrome-192x192.png" sx={{ width: '150px' }} />
            <Typography sx={{ textTransform: 'capitalize', color: blue[900], mt: 3, fontFamily: 'Fredericka the Great' }} variant="h4">
                Tiêu dùng thông minh.
            </Typography>
        </StyledBox>
    )
}

export default PageLoader
