import { useEffect } from 'react'
import { useAppDispatch } from '../states/app/hooks'
import { addCart } from '../states/features/Product/AddToCartSlice'
import { LoadingUserAsync } from '../states/features/User/LoginSlice'

const useLoadUser = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        const tokenTolcal = localStorage.getItem('token')
        if (tokenTolcal) dispatch(LoadingUserAsync(tokenTolcal))
        dispatch(addCart(localStorage.getItem('cart')))
    }, [dispatch])
}

export default useLoadUser
