import React from 'react'
import { MdOutlineProductionQuantityLimits, MdOutlineSell } from 'react-icons/md'
export interface menuProps {
    name: string
    asideName: string
    href: string
    icon: React.ElementType
}
const DealerMenuLeftConfig = [
    {
        name: 'Quản lý sản phẩm',
        asideName: 'DealerManagerProduct',
        href: '/cua-hang/quan-ly-san-pham.html',
        icon: MdOutlineProductionQuantityLimits,
    },
    {
        name: 'Quản lý đơn hàng',
        asideName: 'DealerManagerOrder',
        href: '/cua-hang/quan-ly-don-hang/dang-doi-xac-nhan.html',
        icon: MdOutlineSell,
    },

    // {
    //     name: 'Mã cửa hàng',
    //     asideName: 'QRCodeDealder',
    //     href: '/cua-hang/ma-cua-hang',
    //     icon: MdOutlineQrCode2,
    // },
] as Array<menuProps>

export default DealerMenuLeftConfig
