import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface AdminGetInfor {
    items: {
        wallet: number
        userOnline: number

        profit: {
            total: number
        }
        linkDealer: {
            total: number
        }
        orderConfrimed: {
            total: number
        }

        month: number
        year: number
    }
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: AdminGetInfor = {
    items: {
        wallet: 0,
        userOnline: 0,
        profit: {
            total: 0,
        },
        linkDealer: {
            total: 0,
        },
        orderConfrimed: {
            total: 0,
        },

        month: 0,
        year: 0,
    },
    message: '',
    status: 'idle',
}
interface getProps {
    token: string
}

export const adminGetInfor = createAsyncThunk('admin/system/infor', async (data: getProps) => {
    const { apiGet } = useApi()
    return await apiGet(`admin/system/infor?access_token=${data.token}`)
})

export const AdminGetInfoSlice = createSlice({
    name: 'admin/system',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminGetInfor.fulfilled, (state, { payload }) => {
                const { items } = payload as AdminGetInfor
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(adminGetInfor.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(adminGetInfor.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })
    },
})

export const { clearState } = AdminGetInfoSlice.actions
export const selectAdminGetSystem = (state: RootState) => state.adminGetSystem
export default AdminGetInfoSlice.reducer
