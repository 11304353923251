import React from 'react'
import { Box, ButtonBase, Divider, List, ListItem, Skeleton, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import { Link } from 'react-router-dom'
import LazyImage from '../../../component/LazyImage'
import { useTranslation } from '../../../hooks/Localization'
import { selectCategoryProduct } from '../../../states/features/Category/GetProduct'
import { useAppSelector } from '../../../states/app/hooks'
import ListItemButtonCategory from './ListItemButtonCategory'
import ListItemButtonDealer from './ListItemButtonDealer'
interface Props {
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}
const CategoryMenuLeft: React.FC<Props> = ({ setOpenMenuLeft }) => {
    const { categories, nameCategory, dealers, totalProduct } = useAppSelector(selectCategoryProduct)
    return (
        <List sx={{ pt: 0 }}>
            <ListItem disablePadding sx={{ px: 0.5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <ButtonBase component={Link} to="/" sx={{ my: 0.5, display: 'flex', alignItems: 'center', width: '100% ', justifyContent: 'space-around', px: 0.5 }}>
                        <LazyImage src="/android-chrome-192x192.png?v=1" alt={useTranslation('Webname')} sx={{ height: '100px' }} />
                        {/* <Typography variant="h1" fontWeight="500" color={blue[900]} letterSpacing="0.15rem" sx={{ fontSize: '1.3rem !important ', fontFamily: 'Fredericka the Great' }}>
                            {useTranslation('Webname')}
                        </Typography> */}
                    </ButtonBase>

                    <Divider sx={{ mt: 0.5 }} />
                </Box>
            </ListItem>
            {totalProduct > 0 ? (
                <>
                    <ListItem sx={{ display: categories.length > 0 ? 'flex' : 'none' }}>
                        <Typography variant="h6" sx={{ fontSize: '1.3rem !important', fontFamily: 'Fredericka the Great' }} color={blue[900]}>
                            {nameCategory.charAt(0).toUpperCase()}
                            {nameCategory.slice(1).toLowerCase()}
                        </Typography>
                    </ListItem>

                    {categories.length > 0 && <Divider />}
                    {categories.map((item, index) => {
                        return <ListItemButtonCategory key={index} item={item} setOpenMenuLeft={setOpenMenuLeft} />
                    })}
                    {categories.length > 0 && <Divider />}
                    <ListItem>
                        <Typography variant="h6" sx={{ fontSize: '1.3rem !important', fontFamily: 'Fredericka the Great' }} color={blue[900]}>
                            Cửa hàng
                        </Typography>
                    </ListItem>
                    {dealers.length > 0 && <Divider />}
                    {dealers.map((item, index) => {
                        return <ListItemButtonDealer index={index} item={item} setOpenMenuLeft={setOpenMenuLeft} key={index} />
                    })}
                </>
            ) : (
                <>
                    <ListItem>
                        <Skeleton variant="text" height="33px" sx={{ width: '100%' }} />
                    </ListItem>
                    <Divider />
                    {[1, 2, 3, 4, 5].map((index) => {
                        return (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }} px={2} pb={1} pt={index === 1 ? 1 : 0}>
                                <Skeleton variant="circular" height="30px" width="30px" sx={{ borderRadius: '100%' }} />
                                <Skeleton variant="text" height="30px" sx={{ width: '80%' }} />
                            </Box>
                        )
                    })}
                    <Divider />
                    <ListItem>
                        <Skeleton variant="text" height="33px" sx={{ width: '100%' }} />
                    </ListItem>
                    <Divider />
                    {[1, 2, 3, 4, 5].map((index) => {
                        return (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between' }} px={2} pb={1} pt={index === 1 ? 1 : 0}>
                                <Skeleton variant="circular" height="30px" width="30px" sx={{ borderRadius: '100%' }} />
                                <Skeleton variant="text" height="30px" sx={{ width: '80%' }} />
                            </Box>
                        )
                    })}
                </>
            )}
        </List>
    )
}

export default CategoryMenuLeft
