// import useScrollTrigger from '@mui/material/useScrollTrigger'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { useAppSelector } from '../../../states/app/hooks'
import { selectAddToCart } from '../../../states/features/Product/AddToCartSlice'

interface Props {
    window?: () => Window
    children: React.ReactElement
}

const bottom = 32 * 2.5

const RightButton = (props: Props) => {
    // const { children, window } = props
    const { children } = props
    // const trigger = useScrollTrigger({
    //     target: window ? window() : undefined,
    //     disableHysteresis: true,
    //     threshold: 100,
    // })
    // console.log(trigger)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')
        
        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }

    return (
        <Fade in={true}>
            <Box onClick={handleClick} role="presentation" sx={{ zIndex: 1000, position: 'fixed', bottom: 153 + bottom, right: 16 }}>
                {children}
            </Box>
        </Fade>
    )
}

const Cart = (props: Props) => {
    const { children } = props
    const { items } = useAppSelector(selectAddToCart)
    return (
        <Fade in={true} id="cartProduct">
            <Box component={Link} to="/gio-hang.html" sx={{ zIndex: 1000, position: 'fixed', bottom: 32 + bottom, right: 16 }}>
                {children}
                <Typography
                    sx={{
                        fontSize: '0.8rem !important',
                        fontWeight: '700',
                        left: '7px',
                        top: '-18px',
                        position: 'absolute',
                        backgroundColor: green[900],
                        borderRadius: '100%',
                        width: '27px',
                        height: '27px',
                        textAlign: 'center',
                        lineHeight: '27px',
                        color: '#fff',
                    }}
                >
                    {items.length}
                </Typography>
            </Box>
        </Fade>
    )
}

const Account = (props: Props) => {
    const { children } = props

    return (
        <Fade in={true}>
            <Box component={Link} to="/tong-hop/thong-tin.html" sx={{ zIndex: 1000, position: 'fixed', bottom: 100 + bottom, right: 16 }}>
                {children}
            </Box>
        </Fade>
    )
}
export { RightButton, Cart, Account }
