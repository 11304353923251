import { chooseLocale } from './local'
import { useIntl } from 'react-intl'

export const useTranslation = (_id: string) => {
    const intl = useIntl()
    try {
        return intl.formatMessage({ id: _id })
    } catch (e) {
        return _id
    }
}

export default chooseLocale

