import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

export interface Itemprops {
    name: string
    img: string
    slug: string
}

interface GetListDealerState {
    items: Array<Itemprops>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'success'
}

const initialState: GetListDealerState = {
    items: [],
    message: '',
    status: 'idle',
}
interface getProps {
    page: number
}

export const getListDealerAsync = createAsyncThunk('home/list-dealer/get', async (data: getProps) => {
    const { apiGet } = useApi()
    return await apiGet('dealer/list-dealer/get/' + data.page.toString())
})

export const getListDealerSlice = createSlice({
    name: 'home/list-dealer',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListDealerAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as GetListDealerState
                return { ...state, status: 'success', items, message: '' }
            })
            .addCase(getListDealerAsync.pending, (state) => {
                return { ...state, status: 'loading', items: initialState.items, message: '' }
            })
            .addCase(getListDealerAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: initialState.items, message: error.message as string }
            })
    },
})

export const { clearState } = getListDealerSlice.actions
export const selectListDealer = (state: RootState) => state.getListDealer
export default getListDealerSlice.reducer
