import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface voucherState {
    number: number
    messages: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: voucherState = {
    number: 0,
    messages: '',
    status: 'idle',
}

export const voucherAsync = createAsyncThunk('voucher/root', async (token: string) => {
    const { apiGet } = useApi()
    return await apiGet(`user/get-voucher?access_token=${token}`)
})
export const voucherSlice = createSlice({
    name: 'voucher',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(voucherAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(voucherAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'succeeded', number: payload as number }
            })
            .addCase(voucherAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', messages: error.message as string }
            })
    },
})
export const selectvoucher = (state: RootState) => state.VoucherReducer
export default voucherSlice.reducer
