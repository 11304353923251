import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { loginState, initialState as stateLogin } from '../../User/LoginSlice'

interface GetAdminUserById {
    item: loginState
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdatePhone: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdatePassword: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdateDealerInfo: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdateDealerBalance: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdateDealerActive: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetAdminUserById = {
    item: stateLogin,
    message: '',
    status: 'idle',
    statusUpdatePhone: 'idle',
    statusUpdatePassword: 'idle',
    statusUpdateDealerInfo: 'idle',
    statusUpdateDealerBalance: 'idle',
    statusUpdateDealerActive: 'idle',
}
interface getProps {
    token: string
    _id: string
}

interface postPhoneProps {
    token: string
    phone: string
    _id: string
}

interface postPasswordProps {
    token: string
    password: string
    _id: string
}

interface postDealerInfoProps {
    token: string
    nameDealer: string
    imageName: string
    image: undefined | string | ArrayBuffer | null
    _id: string
}

interface postDealerBalanceProps {
    token: string
    balance: number
    _id: string
}

export const adminGeUserByIdAsync = createAsyncThunk('admin/user/edit-user/by-id', async (data: getProps) => {
    const { token, _id } = data
    const { apiGet } = useApi()
    return await apiGet(`admin/user/find-user-by-id/${_id}?access_token=${token}`)
})

export const adminUpdatePhoneByIdAsync = createAsyncThunk('admin/user/edit-user/update-phone-by-id', async (data: postPhoneProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-phone-by-id?access_token=${data.token}`)
})

export const adminUpdatePasswordByIdAsync = createAsyncThunk('admin/user/edit-user/update-password-by-id', async (data: postPasswordProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-password-by-id?access_token=${data.token}`)
})

export const adminUpdateDealerInfoByIdAsync = createAsyncThunk('admin/user/edit-user/update-dealer-info-by-id', async (data: postDealerInfoProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-dealer-indo-by-id?access_token=${data.token}`)
})
export const adminUpdateDealerBalanceByIdAsync = createAsyncThunk('admin/user/edit-user/update-dealer-balance-by-id', async (data: postDealerBalanceProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-dealer-balance-by-id?access_token=${data.token}`)
})

export const adminUpdateDealerActiveByIdAsync = createAsyncThunk('admin/user/edit-user/update-dealer-active-by-id', async (data: getProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-dealer-active-by-id?access_token=${data.token}`)
})
export const adminEditUserByIdSlice = createSlice({
    name: 'admin/user/edit-user',
    initialState,
    reducers: {
        clearState: (state) => {
            return {
                ...state,
                status: 'idle',
                statusUpdatePhone: 'idle',
                statusUpdatePassword: 'idle',
                statusUpdateDealerInfo: 'idle',
                statusUpdateDealerBalance: 'idle',
                statusUpdateDealerActive: 'idle',
                message: '',
            }
        },
    },
    extraReducers: (builder) => {
        builder
            //Get info --------------------------------------------
            .addCase(adminGeUserByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    status: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminGeUserByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.item,
                }
            })
            .addCase(adminGeUserByIdAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    item: initialState.item,
                    message: '',
                }
            })
            //Update phone user--------------------------------------------
            .addCase(adminUpdatePhoneByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    statusUpdatePhone: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminUpdatePhoneByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdatePhone: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdatePhoneByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdatePhone: 'loading',
                    message: '',
                }
            })
            //Update password user--------------------------------------------
            .addCase(adminUpdatePasswordByIdAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusUpdatePassword: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminUpdatePasswordByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdatePassword: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdatePasswordByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdatePassword: 'loading',
                    message: '',
                }
            })
            //Update dealer info--------------------------------------------
            .addCase(adminUpdateDealerInfoByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    statusUpdateDealerInfo: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminUpdateDealerInfoByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdateDealerInfo: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdateDealerInfoByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdateDealerInfo: 'loading',
                    message: '',
                }
            })
            //Update dealer balance--------------------------------------------
            .addCase(adminUpdateDealerBalanceByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    statusUpdateDealerBalance: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminUpdateDealerBalanceByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdateDealerBalance: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdateDealerBalanceByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdateDealerBalance: 'loading',
                    message: '',
                }
            })

            //Update dealer active--------------------------------------------
            .addCase(adminUpdateDealerActiveByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    statusUpdateDealerActive: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminUpdateDealerActiveByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdateDealerActive: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdateDealerActiveByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdateDealerActive: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearState } = adminEditUserByIdSlice.actions
export const selectAdminEditUserById = (state: RootState) => state.adminEditUser
export default adminEditUserByIdSlice.reducer
