import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetDealerItem } from '../Dealer/DealerSlice'
export interface roleProps {
    customer: boolean
    admin: boolean
    dealer: boolean
}
interface DealerProps extends GetDealerItem {
    actived: boolean
}

interface balanceProps {
    balance: number
}

interface bahProps {
    current: number
    product: number
}

export interface walletProps {
    customer: balanceProps
    dealer: balanceProps
}

export interface loginState {
    _id: string
    username: string
    parent_ref: string
    messages: string
    token: string
    phone: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    statusLoading: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
    role: roleProps
    dealer: DealerProps
    createdAt?: Date
    updatedAt?: Date
    wallet: walletProps
    deliveryAddress: Array<deliveryAddressProps>
    bah: bahProps
}
export interface addressProps {
    city: string
    district: string
    ward: string
    address: string
    default: boolean
    nameCity: string
    nameDistrict: string
    nameWard: string
    id: number
}
export interface deliveryAddressProps extends addressProps {
    name: string
    phone: string
}
export interface informationState {
    step: number
    fullname: string
    passport: string
    birthday: string
    address: Array<addressProps>
    bankName: string
    bankBranch: string
    bankAccount: string
    bankNumber: string
    linkAvatar: string
}

interface userLoginState {
    username: string
    password: string
    captcha: number | string
}

export const initialState: loginState = {
    _id: '',
    username: '',
    parent_ref: '',
    messages: '',
    token: '',
    phone: '',
    status: 'idle',
    statusLoading: 'idle',
    wallet: {
        customer: { balance: 0 },
        dealer: { balance: 0 },
    },
    bah: {
        current: 0,
        product: 0,
    },
    information: {
        step: 0,
        fullname: '',
        passport: '',
        birthday: '',
        address: [],
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
        linkAvatar: '',
    },
    role: {
        customer: true,
        admin: false,
        dealer: false,
    },
    dealer: {
        name: '',
        img: '',
        _id: '',
        slug: '',
        actived: false,
    },
    deliveryAddress: [],
}

export const LoginAsync = createAsyncThunk('user/login', async (data: userLoginState) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data }, 'user/sign-in')
})

export const LoadingUserAsync = createAsyncThunk('user/login/loading', async (token: string) => {
    const { apiGet } = useApi()
    return await apiGet(`user/load-user?access_token=${token}`)
})

export const userSlice = createSlice({
    name: 'user/loign',
    initialState,
    reducers: {
        clearState: () => initialState,
        clearAsync: (state) => {
            return { ...state, status: 'idle' }
        },
        updateBalance: (state, { payload }) => {
            return { ...state, balance: payload }
        },

        updateBah: (state, { payload }) => {
            return { ...state, bah: payload }
        },
        updateInformation: (state, { payload }) => {
            return { ...state, information: payload }
        },
        updateDealer: (state, { payload }) => {
            return { ...state, dealer: payload }
        },
        updateRole: (state, { payload }) => {
            return { ...state, role: payload }
        },
        setToken: (state, { payload }) => {
            return { ...state, token: payload }
        },

        setDeliverAddress: (state, { payload }) => {
            return { ...state, deliveryAddress: payload }
        },
        updateWallet: (state, { payload }) => {
            return { ...state, wallet: payload }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(LoginAsync.fulfilled, (state, { payload }) => {
                const newState = { ...(payload as loginState), status: 'succeeded', statusLoading: 'sucessded' }
                localStorage.setItem('token', newState.token)
                return { ...state, ...(newState as loginState) }
            })
            .addCase(LoginAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, messages: error.message as string, status: 'failed' }
                return { ...state, ...(newState as loginState) }
            })
            .addCase(LoadingUserAsync.pending, (state) => {
                return { ...state, statusLoading: 'loading' }
            })
            .addCase(LoadingUserAsync.fulfilled, (state, { payload }) => {
                const newState = { ...(payload as loginState), status: 'sucessded', statusLoading: 'succeeded' }
                return { ...state, ...(newState as loginState) }
            })
            .addCase(LoadingUserAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, messages: error.message as string }
                return { ...state, ...(newState as loginState) }
            })
    },
})
export const { clearState, updateBah, clearAsync, updateRole, updateBalance, setToken, updateInformation, updateDealer, setDeliverAddress, updateWallet } = userSlice.actions
export const selectLogin = (state: RootState) => state.login
export default userSlice.reducer
