const local: any = {
    vi: {
        Webname: 'SKEY VIET',
        'Home Page': 'Trang chủ',
        Dashboard: 'Tổng hợp',
        Overview: 'Tổng quan',
        Wallet: 'Ví tiền',
        Link: 'Link',
        Account: 'Tài khoản',
        Profile: 'Hồ sơ',
        Report: 'Lịch sử',
        Support: 'Hỗ trợ',
        Logout: 'Thoát',
        Login: 'Đăng nhập',
        Register: 'Đăng kí',
        'Change Theme': 'Thay Đổi Chủ Đề',
        Submit: 'Gửi đi',
        'Have a account': 'Bạn có tài khoản',
        now: 'bây giờ',
        Loading: 'Đang tải',
        'Please fill all input': 'Điền tất cả thông tin vào',
        Captcha: '',
        Reload: 'Tải lại',
        Error: 'Lỗi',
        Success: 'Thành công',
        Referen: 'SĐT người giới thiệu',
        Username: 'Tên đăng nhập',
        Password1: 'Mật khẩu',
        'Confirm Password 1': 'Nhập lại mật khẩu',
        Email: 'Email',
        "Don't have an account": 'Bạn chưa có tài khoản',
        'The username or password is incorrect': 'Tên hoặc mật khẩu sai',
        'Login Sucess': 'Đăng nhập thành công',
        Tree: 'Cây',
        'Reference Link': 'Liên kết tham khảo',
        Copy: 'Sao chép',
        'Register Success': 'Đăng ký thành công',
        Now: 'Bây giờ',
        'Successful account registration, welcome to MoCake': 'Đăng ký tài khoản thành công, chào mừng Đến với BachHoaViet',
        'Account has been successfully registered. ': 'Tài khoản  đã được đăng ký thành công',
        'Please check your email address to activate this account': 'Vui lòng kiểm tra địa chỉ email của bạn để kích hoạt tài khoản này',
        'Back Home': 'Quay lại trang chủ',
        'Oops, page not found.': 'Rất tiếc! không tìm thấy trang.',
        'Forgot Password': 'Quên mật khẩu',
        'Please check your email': 'Vui lòng kiểm tra địa chỉ email của bạn',
        'Change password successfully': 'Thay Đổi Mật Khẩu Thành Công',
        Code: 'Code',
        'New Password': 'Mật Khẩu Mới',
        'New Account': 'Tài Khoản Mới',
        Change: 'Thay Đổi',
        'Old Password1': 'Mật Khẩu Cấp Cũ',
        'New Password1': 'Mật Khẩu Cấp Mới',
        'Confirm Password1': 'Nhập Lại Mật Khẩu Cấp Mới',
        'Old Password2': 'Mật Khẩu Cấp 2 Cũ',
        'New Password2': 'Mật Khẩu Cấp 2 Mới',
        Tranfer: 'Chuyển Khoản',
        'Copy Success': 'Sao chép thành công',
        'Ref Link': 'Gửi liên kết giới thiệu',
        Note: 'Chú thích',
        Created: 'Tạo mới',
        'System Tree': 'Cây Hệ Thống',
        Log: 'Nhật Ký',
        'Change Password 1 Successful': 'Thay đổi mật khẩu thành công',
    },
}

export const chooseLocale = (locale: string) => {
    return local[locale]
}
