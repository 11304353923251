const useOption = () => {
    return window.location.search
        .slice(1)
        .split('&')
        .reduce(function _reduce(a: any, b: any) {
            b = b.split('=')
            a[b[0]] = decodeURIComponent(b[1])
            return a
        }, {})
}

export default useOption
