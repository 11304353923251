import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface password1State {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface userChangePasswrd1State {
    oldPassword: string
    newPassword: string
    confNewPassword: string
    token: string
    captcha: number | string
}

const initialState: password1State = {
    message: '',
    status: 'idle',
}

export const ChangePassword1Async = createAsyncThunk('user/change-password1', async (data: userChangePasswrd1State) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/change-password1?access_token=${data.token}`)
})

export const Password1Slice = createSlice({
    name: 'user/change-password1',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(ChangePassword1Async.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as password1State) }
            })
            .addCase(ChangePassword1Async.fulfilled, (state) => {
                const newState = { ...initialState, status: 'succeeded' }
                return { ...state, ...(newState as password1State) }
            })
            .addCase(ChangePassword1Async.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as password1State) }
            })
    },
})
export const { clearState } = Password1Slice.actions
export const selectPassword1 = (state: RootState) => state.changePassword1
export default Password1Slice.reducer
