import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface getDashboardProps {
    token: string
    _id: string
}
interface WalletDealerProps {
    balance: number
    deposit: number
    watting: number
    selled: number
    cancel: number
}

interface systemProps {
    countDirect: number
    countSystemMember: number
}

interface WalletCustomerProps {
    buy: number
    buyMonth: number
    profit: number
    link: number
    next_month: number
    listMonth: Array<itemMonth>
    f1Order: number
    systemOrder: number
    voucher: number
    level: number
}

interface itemMonth {
    time: string
    sum: number
}

interface DashboardState {
    dealer: WalletDealerProps
    customer: WalletCustomerProps
    system: systemProps
    message: string
    status: 'idle' | 'loading' | 'failed' | 'successed'
    maxOutBah: number
}

const initialState: DashboardState = {
    dealer: {
        balance: 0,
        deposit: 0,
        watting: 0,
        selled: 0,
        cancel: 0,
    },
    customer: {
        buy: 0,
        buyMonth: 0,
        profit: 0,
        link: 0,
        next_month: 0,
        listMonth: [],
        f1Order: 0,
        systemOrder: 0,
        voucher: 0,
        level: 0,

    },
    system: {
        countDirect: 0,
        countSystemMember: 0,
    },
    maxOutBah: 0,
    message: '',
    status: 'idle',
}

export const GetDashboardAsync = createAsyncThunk('dashboard/getDashboard', async (props: getDashboardProps) => {
    const { apiGet } = useApi()
    const response = await apiGet('user/get-dashboard?access_token=' + props.token + '&_id=' + props._id)
    return response
})

export const dashboardSlice = createSlice({
    name: 'user/dashboard',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetDashboardAsync.pending, (state) => {
                return { ...state, status: 'loading', message: '' }
            })
            .addCase(GetDashboardAsync.fulfilled, (state, { payload }) => {
                const { dealer, system, customer, maxOutBah } = payload as DashboardState
                return { ...state, status: 'successed', dealer, system, customer, maxOutBah }
            })
            .addCase(GetDashboardAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})

export const { clearState } = dashboardSlice.actions
export const selectDashboard = (state: RootState) => state.dashboard
export default dashboardSlice.reducer
