import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface CityItem {
    name_with_type: string
    code: string
}

interface CityState {
    items: Array<CityItem>
    message: string
    status: 'idle' | 'loading' | 'failed'
}

interface getPros {
    token: string
}

const initialState: CityState = {
    items: [],
    message: '',
    status: 'idle',
}

export const getCityAsync = createAsyncThunk('user/city/get', async (data: getPros) => {
    const { apiGet } = useApi()
    return await apiGet(`user/get-city?access_token=${data.token}`)
})

export const CitySlice = createSlice({
    name: 'user/city',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCityAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(getCityAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'idle', items: payload as Array<CityItem> }
            })
            .addCase(getCityAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})
export const selectCity = (state: RootState) => state.city
export default CitySlice.reducer
