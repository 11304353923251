import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetProductItem } from '../../features/Product/AddProduct'

interface GetProductState {
    items: Array<GetProductItem>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetProductState = {
    items: [],
    message: '',
    status: 'idle',
}
interface getProps {
    token: string
    page: number
}

export const getProductDealerAsync = createAsyncThunk('dealer/get-product', async (data: getProps) => {
    const { token, page } = data
    const { apiGet } = useApi()
    return await apiGet(`dealer/get-product/${page}?access_token=${token}`)
})

export const getProductSlice = createSlice({
    name: 'dealer/get-product',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductDealerAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [], message: '' }
            })
            .addCase(getProductDealerAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as GetProductState
                return { ...state, status: 'sucessded', items, message: '' }
            })
            .addCase(getProductDealerAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})
export const { clearState } = getProductSlice.actions
export const selectProductDealer = (state: RootState) => state.getProductDealer
export default getProductSlice.reducer
