import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { informationState } from '../LoginSlice'

interface UploadAddressState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
}

interface postPros {
    token: string
    captcha: number | string
    address: string
    district: string
    ward: string
    city: string
}

const initialState: UploadAddressState = {
    message: '',
    status: 'idle',
    information: {
        step: 0,
        fullname: '',
        passport: '',
        birthday: '',
        address: [],
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
        linkAvatar: '',
    },
}

export const UploadAddressAsync = createAsyncThunk('user/upload-address/address', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/profile/address-update?access_token=${data.token}`)
})

export const UpLoadAddressSlice = createSlice({
    name: 'user/upload-address',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UploadAddressAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadAddressState) }
            })
            .addCase(UploadAddressAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', information: payload as informationState }
                return { ...state, ...(newState as UploadAddressState) }
            })
            .addCase(UploadAddressAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadAddressState) }
            })
    },
})
export const selectUploadAddress = (state: RootState) => state.uploaddAddress
export default UpLoadAddressSlice.reducer
