import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetDealerItem } from './DealerSlice'

interface itemProp extends GetDealerItem {
    phone: string
}

interface GetDealerQrCodeState {
    item: itemProp
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetDealerQrCodeState = {
    item: {
        name: '',
        slug: '',
        _id: '',
        img: '',
        phone: '',
    },
    message: '',
    status: 'idle',
}
interface getProps {
    slug: string
}

export const getDealerQrCodeAllAsync = createAsyncThunk('dealer/qr-code/get-info', async (data: getProps) => {
    const { apiGet } = useApi()
    return await apiGet('dealer/get-qr-code-by-slug/' + data.slug)
})

export const getDealerQrCodeSlice = createSlice({
    name: 'dealer/qr-code',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDealerQrCodeAllAsync.pending, (state) => {
                return { ...state, status: 'loading', item: initialState.item, message: '' }
            })
            .addCase(getDealerQrCodeAllAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'sucessded', item: payload as itemProp, message: '' }
            })
            .addCase(getDealerQrCodeAllAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', item: initialState.item, message: error.message as string }
            })
    },
})

export const selectDealerQrCode = (state: RootState) => state.dealerQrocde
export default getDealerQrCodeSlice.reducer
