import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetDealerItem } from '../Dealer/DealerSlice'
import { GetProductItem } from '../Product/AddProduct'
import { CategoryItem } from './GetCategorySlice'

export interface CategoryProps extends CategoryItem {
    qty: number
}

export interface dealers extends GetDealerItem {
    qty: number
}

interface CategoryState {
    categories: Array<CategoryProps>
    nameCategory: string
    productIds: Array<GetProductItem>
    dealers: Array<dealers>
    message: string
    page: number
    size: number
    totalProduct: number
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: CategoryState = {
    categories: [],
    size: 100,
    productIds: [],
    dealers: [],
    nameCategory: '',
    message: '',
    page: 0,
    totalProduct: 1,
    status: 'idle',
}
interface getPros {
    slug: string
    page: number
    sh: string | undefined
    so: string | undefined
    ho: string | undefined
    se: string | undefined
}

export const getCategoryProductAllAsync = createAsyncThunk('category/product/get-all', async (data: getPros) => {
    const { apiGet } = useApi()
    return await apiGet('category/get-product/' + data.slug + '/' + data.page + `?sh=${data.sh}&ho=${data.ho}&so=${data.so}&se=${data.se}`)
})

export const getCategoryProductSlice = createSlice({
    name: 'category/product',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, productIds: [], status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoryProductAllAsync.pending, () => {
                return { ...initialState, status: 'loading' }
            })
            .addCase(getCategoryProductAllAsync.fulfilled, (state, { payload }) => {
                const { size, categories, nameCategory, productIds, dealers, page, totalProduct } = payload as CategoryState
                return { ...state, status: 'sucessded', categories, nameCategory, productIds, dealers, page, totalProduct, size }
            })
            .addCase(getCategoryProductAllAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = getCategoryProductSlice.actions
export const selectCategoryProduct = (state: RootState) => state.getCategoryProduct
export default getCategoryProductSlice.reducer
