import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

export interface GetDealerItem {
    name: string
    slug: string
    _id: string
    img: string
}

interface GetDealerState {
    items: Array<GetDealerItem>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetDealerState = {
    items: [],
    message: '',
    status: 'idle',
}
interface getProps {
    all: boolean
}

export const getDealerAllAsync = createAsyncThunk('dealer/get-all', async (data: getProps) => {
    const { apiGet } = useApi()
    return await apiGet('dealer/get-all/' + data.all)
})

export const getDealerSlice = createSlice({
    name: 'dealer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDealerAllAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(getDealerAllAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'sucessded', items: payload as Array<GetDealerItem> }
            })
            .addCase(getDealerAllAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})

export const selectGetAllGetDealerHome = (state: RootState) => state.getDealerHome
export default getDealerSlice.reducer
