import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetDealerItem } from '../Dealer/DealerSlice'
export interface GetProductItem {
    sale: number
    price: number
    name: string
    mainImage: string
    view: number
    slug: string
    _id: string
    actived: boolean
    createdAt?: Date
    dealer: GetDealerItem
    hotProduct: boolean
}
interface AddProductState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: AddProductState = {
    message: '',
    status: 'idle',
}
interface postProps {
    token: string
    priceOrigin: number | string
    sale: number
    nameProduct: string
    images: string[]
    imageNames: string[]
    captcha: number | string
    description: string
    sortText: string
}

export const addProductAsync = createAsyncThunk('dealer/product/add', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `dealer/product-add?access_token=${data.token}`)
})

export const addProductSlice = createSlice({
    name: 'dealer/product',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addProductAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(addProductAsync.fulfilled, (state) => {
                return { ...state, status: 'sucessded', message: '' }
            })
            .addCase(addProductAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = addProductSlice.actions
export const selectAddProduct = (state: RootState) => state.addProduct
export default addProductSlice.reducer
