import { DashboardOutlined } from '@mui/icons-material'
import React from 'react'
import { AiOutlineBank, AiOutlineSend } from 'react-icons/ai'
import { MdOutlineCategory, MdOutlineManageAccounts, MdOutlineProductionQuantityLimits, MdOutlinePublishedWithChanges } from 'react-icons/md'
export interface menuProps {
    name: string
    asideName: string
    href: string
    icon: React.ElementType
}
const AdminMenuLeftConfig = [
    // {
    //     name: 'Thông tin hệ thống',
    //     asideName: 'AdminOverview',
    //     href: '/admin/thong-tin-he-thong.html',
    //     icon: MdOutlineDashboard,
    // },
    {
        name: 'Tài khoản ngân hàng',
        asideName: 'AdminBank',
        href: '/admin/tai-khoan-ngan-hang.html',
        icon: AiOutlineBank,
    },
    {
        name: 'Nhật ký số dư',
        asideName: 'AdminDeposit',
        href: '/admin/danh-sach-nap-tien.html',
        icon: MdOutlinePublishedWithChanges,
    },
    {
        name: 'Danh sách rút tiền',
        asideName: 'AdminWithdraw',
        href: '/admin/danh-sach-rut-tien.html',
        icon: AiOutlineSend,
    },
    // {
    //     name: 'Nhật ký yêu cầu nạp',
    //     asideName: 'AdminDepositPost',
    //     href: '/admin/nhat-ky-yeu-cau-nap-tien.html',
    //     icon: MdOutlinePriceChange,
    // },
    {
        name: 'Xem khách hàng',
        asideName: 'AdminManagementAccount',
        href: '/admin/quan-ly-khach-hang.html',
        icon: MdOutlineManageAccounts,
    },
    {
        name: 'Quản lý danh mục',
        asideName: 'AdminCategory',
        href: '/admin/quan-ly-danh-muc.html',
        icon: MdOutlineCategory,
    },
    {
        name: 'Quản lý sản phẩm',
        asideName: 'AdminProduct',
        href: '/admin/quan-ly-san-pham.html',
        icon: MdOutlineProductionQuantityLimits,
    },
    {
        name: 'Slide',
        asideName: 'AdminSlide',
        href: '/admin/quan-ly-slide.html',
        icon: DashboardOutlined,
    },
] as Array<menuProps>

export default AdminMenuLeftConfig
