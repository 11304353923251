import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetProductItem } from './AddProduct'
interface GetProductHotHomeState {
    items: Array<GetProductItem>
    size: number
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetProductHotHomeState = {
    items: [],
    size: 100,
    message: '',
    status: 'idle',
}
interface getProps {
    page: number
}

export const getProductHotAsync = createAsyncThunk('home/product-hot/get', async (data: getProps) => {
    const { page } = data
    const { apiGet } = useApi()
    return await apiGet(`product/home-hot/${page}`)
})

export const getProductHotHome = createSlice({
    name: 'home/product-hot',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, items: [], message: '', status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductHotAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(getProductHotAsync.fulfilled, (state, { payload }) => {
                const { items, size } = payload as GetProductHotHomeState
                return { ...state, status: 'sucessded', message: '', items, size }
            })
            .addCase(getProductHotAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string, items: [] }
            })
    },
})
export const { clearState } = getProductHotHome.actions
export const selectProductHotHome = (state: RootState) => state.homeGetHotProduct
export default getProductHotHome.reducer
