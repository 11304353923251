import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

export interface GetDealerLogAmountSate {
    value: number
    createdAt: Date
    note: string
    system_join: {
        end: Date
    }
    typeAmount: {
        type: boolean
        before: number
        after: number
    }
}

interface GetDealerState {
    items: Array<GetDealerLogAmountSate>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'success'
}

const initialState: GetDealerState = {
    items: [],
    message: '',
    status: 'idle',
}
interface getProps {
    token: string
    page: number
    isSystemJoinPage: boolean
}

export const getDealerLogAmountAsync = createAsyncThunk('dealer/log-amount/get', async (data: getProps) => {
    const { apiGet } = useApi()
    const { page, token, isSystemJoinPage } = data
    return await apiGet('dealer/log-amount/get/' + page + '/' + isSystemJoinPage + '?access_token=' + token)
})

export const getDealerLogAmountSlice = createSlice({
    name: 'dealer/log-amount',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDealerLogAmountAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(getDealerLogAmountAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'success', items: payload as Array<GetDealerLogAmountSate> }
            })
            .addCase(getDealerLogAmountAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})

export const { clearState } = getDealerLogAmountSlice.actions
export const selectDealetLogAmount = (state: RootState) => state.logDealerAmount
export default getDealerLogAmountSlice.reducer
