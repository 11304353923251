import React from 'react'
import { MdOutlineAddBusiness, MdOutlineAddShoppingCart, MdOutlineAddTask } from 'react-icons/md'
import { VscPreview } from 'react-icons/vsc'
import { AiOutlineUser, AiOutlineHome, AiOutlineShopping, AiOutlineStar } from 'react-icons/ai'
import { BiWallet } from 'react-icons/bi'
export interface menuProps {
    name: string
    asideName: string
    href: string
    icon: React.ElementType
}
const UserMenuLeftConfig = [
    {
        name: 'Trang chủ',
        asideName: 'Home',
        href: '/',
        icon: AiOutlineHome,
        role: { customer: true },
    },
    {
        name: 'Tổng hợp',
        asideName: 'Dashboard',
        href: '/tong-hop.html',
        icon: VscPreview,
    },

    {
        name: 'Hồ sơ',
        asideName: 'Profile',
        href: '/ho-so.html',
        icon: AiOutlineUser,
    },
    {
        name: 'Địa chỉ giao hàng',
        asideName: 'AddressDelivery',
        href: '/dia-chi-giao-hang.html',
        icon: MdOutlineAddBusiness,
    },
    {
        name: 'Lịch sử mua hàng',
        asideName: 'SendOrder',
        href: '/lich-su/gui-don/dang-doi-xac-nhan.html',
        icon: AiOutlineStar,
    },
    // {
    //     name: 'Phát triển đại lý',
    //     asideName: 'SystemJoin',
    //     href: '/phat-trien-he-thong.html',
    //     icon: MdOutlineArrowUpward,
    // },
    {
        name: 'Nhật ký số dư',
        asideName: 'DealerLogAmount',
        href: '/nhat-ky-so-du.html',
        icon: MdOutlineAddTask,
    },
    // {
    //     name: 'Nạp tiền',
    //     asideName: 'Deposit',
    //     href: '/nap-tien.html',
    //     icon: AiOutlinePlusCircle,
    // },
    {
        name: 'Rút tiền',
        asideName: 'WithDraw',
        href: '/rut-tien.html',
        icon: BiWallet,
    },
    {
        name: 'Sản phẩm đã mua',
        asideName: 'ProductBuyed',
        href: '/lich-su-mua-hang.html',
        icon: AiOutlineShopping,
    },
    // {
    //     name: 'Lịch sử giao dịch',
    //     asideName: 'HistoriesTrade',
    //     href: '/lich-su-giao-dich/mua-hang.html',
    //     icon: AiOutlineHistory,
    // },
    {
        name: 'Giỏ hàng',
        asideName: 'Cart',
        href: '/gio-hang.html',
        icon: MdOutlineAddShoppingCart,
    },
] as Array<menuProps>

export default UserMenuLeftConfig
