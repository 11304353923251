const useApi = () => {
    const { REACT_APP_PUBLIC_API } = process.env
    const apiPost = async (body: object, action: string = '') => {
        const response = await fetch(`${REACT_APP_PUBLIC_API + '/' + action}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        const data = await response.json()

        return new Promise((resolve, reject) => {
            if (response.status !== 200) {
                reject(data)
            } else {
                resolve(data)
            }
        })
    }
    const apiGet = async (action: string = '') => {
        const { REACT_APP_PUBLIC_API } = process.env
        const response = await fetch(`${REACT_APP_PUBLIC_API + '/' + action}`, {
            method: 'GET',
        })
        const data = await response.json()
        return new Promise((resolve, reject) => {
            if (response.status !== 200) {
                reject(data)
            } else {
                resolve(data)
            }
        })
    }
    return { apiGet, apiPost }
}
export default useApi
