import { AlertColor } from '@mui/material'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface ToastState {
    show: boolean
    message: string
    severity: AlertColor
}

const initialState: ToastState = {
    show: false,
    message: '',
    severity: 'success',
}

export const toastSlice = createSlice({
    name: 'user/toast',
    initialState,
    reducers: {
        eventShow: (state, { payload }) => {
            return { ...state, show: payload }
        },
        eventMessage: (state, { payload }) => {
            return { ...state, message: payload }
        },
        eventSeverity: (state, { payload }) => {
            return { ...state, severity: payload }
        },

        changeState: (state, { payload }) => {
            return { ...state, show: payload.show as boolean, message: payload.message as string, severity: payload.severity as AlertColor }
        },
    },
    extraReducers: () => {},
})

export const { eventShow, changeState, eventMessage, eventSeverity } = toastSlice.actions
export const selectToast = (state: RootState) => state.toast
export default toastSlice.reducer
