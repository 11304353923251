import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { informationState } from '../LoginSlice'

interface UploadInfoState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
}

interface postPros {
    token: string
    captcha: number | string
    fullname: string
    passport: string
    birthday: Date | null
}

const initialState: UploadInfoState = {
    message: '',
    status: 'idle',
    information: {
        step: 0,
        fullname: '',
        passport: '',
        birthday: '',
        address: [],
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
        linkAvatar: '',
    },
}

export const UploadInfoAsync = createAsyncThunk('user/upload-info/info', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/profile/info-update?access_token=${data.token}`)
})

export const UpLoadInfoSlice = createSlice({
    name: 'user/upload-info',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UploadInfoAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadInfoState) }
            })
            .addCase(UploadInfoAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', information: payload as informationState }
                return { ...state, ...(newState as UploadInfoState) }
            })
            .addCase(UploadInfoAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadInfoState) }
            })
    },
})
export const selectUploadInfo = (state: RootState) => state.uploadInfo
export default UpLoadInfoSlice.reducer
