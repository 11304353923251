import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { GetProductItem } from '../../../features/Product/AddProduct'

interface GetAdminFindProudctState {
    items: Array<GetProductItem>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetAdminFindProudctState = {
    items: [],
    message: '',
    status: 'idle',
}
interface getProps {
    token: string
    page: number
    name: string
}

export const getAdminFindProudctAsync = createAsyncThunk('admin/find-product', async (data: getProps) => {
    const { token, page, name } = data
    const { apiGet } = useApi()
    return await apiGet(`admin/find-product/${page}?access_token=${token}&name=${name}`)
})

export const getAdminFindProudctSlice = createSlice({
    name: 'admin/find-product',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminFindProudctAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [], message: '' }
            })
            .addCase(getAdminFindProudctAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as GetAdminFindProudctState
                return { ...state, status: 'sucessded', items, message: '' }
            })
            .addCase(getAdminFindProudctAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})

export const { clearState } = getAdminFindProudctSlice.actions

export const selectAdminFindProudctDealer = (state: RootState) => state.getAdminFindPruduct
export default getAdminFindProudctSlice.reducer
