import React from 'react'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { useAppDispatch, useAppSelector } from '../../states/app/hooks'
import List from '@mui/material/List'
import UserMenu from './config/UserMenu'
import { clearState as clearUser, selectLogin as selectUser } from '../../states/features/User/LoginSlice'
import { selectAside } from '../../states/features/Aside/AsideSlice'
import LazyImage from '../LazyImage'
import { useTranslation } from '../../hooks/Localization'
import { Box, ButtonBase, Divider, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'
import ListItemButtonExtend from './components/ListItemButtonExtend'
import { blue } from '@mui/material/colors'
import { Link } from 'react-router-dom'
import DealerMenuLeftConfig from './config/DelaerMenu'
import AdminMenuLeftConfig from './config/AdminMenu'
import { VscSignOut } from 'react-icons/vsc'
// import { FaRegHandshake } from 'react-icons/fa'

interface menuProps {
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}
const UserMenuLeft: React.FC<menuProps> = ({ setOpenMenuLeft }) => {
    const dispatch = useAppDispatch()
    const { token, role, dealer } = useAppSelector(selectUser)
    const { asideName } = useAppSelector(selectAside)
    return (
        <List sx={{ pt: 0 }}>
            <ListItem disablePadding sx={{ px: 0.5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <ButtonBase component={Link} to="/" sx={{ my: 0.5, display: 'flex', alignItems: 'center', width: '100% ', justifyContent: 'space-around', px: 0.5 }}>
                        <LazyImage src="/android-chrome-192x192.png?v=2" alt={useTranslation('Webname')} sx={{ height: '100px' }} />
                        {/* <Typography variant="h1" fontWeight="500" color={blue[900]} letterSpacing="0.15rem" sx={{ fontSize: '1.3rem !important ', fontFamily: 'Fredericka the Great' }}>
                            {useTranslation('Webname')}
                        </Typography> */}
                    </ButtonBase>

                    <Divider sx={{ mt: 0.5 }} />
                </Box>
            </ListItem>

            <ListItem>
                <Typography variant="h6" sx={{ fontSize: '1.3rem !important', fontFamily: 'Fredericka the Great' }} color={blue[900]}>
                    Khách hàng
                </Typography>
            </ListItem>
            <Divider />
            {UserMenu.map((obj, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButtonExtend setOpenMenuLeft={setOpenMenuLeft} {...obj} currentAside={asideName} />
                </ListItem>
            ))}
            <Divider />
            {!role.customer && (
                <>
                    <ListItem>
                        <Typography variant="h6" sx={{ fontSize: '1.3rem !important', fontFamily: 'Fredericka the Great' }} color={blue[900]}>
                            Cửa hàng
                        </Typography>
                    </ListItem>
                    <Divider />
                </>
            )}

            {/* <ListItem disablePadding>
                <ListItemButtonExtend name="Hợp tác bán hàng" setOpenMenuLeft={setOpenMenuLeft} href="/hop-tac-ban-hang.html" asideName="StoreRegister" icon={FaRegHandshake} currentAside={asideName} />
            </ListItem> */}
            {!role.customer && (
                <>
                    {DealerMenuLeftConfig.map((obj, index) => (
                        <Box key={index}>
                            {obj.asideName !== 'QRCodeDealder' ? (
                                <>{dealer.actived && <ListItemButtonExtend setOpenMenuLeft={setOpenMenuLeft} {...obj} currentAside={asideName} />}</>
                            ) : (
                                <>{dealer.actived && <ListItemButtonExtend setOpenMenuLeft={setOpenMenuLeft} {...{ ...obj, href: `${obj.href}/${dealer.slug}.html` }} currentAside={asideName} />}</>
                            )}
                        </Box>
                    ))}
                </>
            )}
            {role.admin && (
                <>
                    <Divider />
                    <ListItem>
                        <Typography variant="h6" sx={{ fontSize: '1.3rem !important', fontFamily: 'Fredericka the Great' }} color={blue[900]}>
                            Quản trị hệ thống
                        </Typography>
                    </ListItem>
                    <Divider />
                    {AdminMenuLeftConfig.map((obj, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButtonExtend setOpenMenuLeft={setOpenMenuLeft} {...obj} currentAside={asideName} />
                        </ListItem>
                    ))}
                </>
            )}
            <ListItemButton
                onClick={() => {
                    token && dispatch(clearUser())
                }}
            >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <SvgIcon component={VscSignOut} inheritViewBox />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: '400' }}>
                            Đăng xuất
                        </Typography>
                    }
                />
            </ListItemButton>
        </List>
    )
}
export default UserMenuLeft
