import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface QrCodeInputPriceState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: QrCodeInputPriceState = {
    message: '',
    status: 'idle',
}
interface postProps {
    token: string
    slug: string
    amount: number
}

export const postPriceFromQrCode = createAsyncThunk('qr-code/price/submit', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `qr-code/price/submit?access_token=${data.token}`)
})

export const qrcodeInputPriceSlice = createSlice({
    name: 'qr_code/price',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(postPriceFromQrCode.pending, (state) => {
                return { ...state, status: 'loading', message: '' }
            })
            .addCase(postPriceFromQrCode.fulfilled, (state) => {
                return { ...state, status: 'sucessded', message: '' }
            })
            .addCase(postPriceFromQrCode.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})

export const { clearState } = qrcodeInputPriceSlice.actions
export const selectQrCodeInputPrice = (state: RootState) => state.qrcodeInputPrice
export default qrcodeInputPriceSlice.reducer
