import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface SocketState {
    connected: boolean
}
const initialState: SocketState = {
    connected: false,
}

export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        changeSocket: (state, { payload }) => {
            return { ...state, connected: payload }
        },
    },
})

export const selectSocket = (state: RootState) => state.socket
export const { changeSocket } = socketSlice.actions
export default socketSlice.reducer
