import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

export interface itemProps {
    note: string
    deposit: {
        startDate: Date
        endDate: Date
        finish: boolean
        amount: number
    }
}

interface logsDepositState {
    items: Array<itemProps>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: logsDepositState = {
    items: [],
    message: '',
    status: 'idle',
}

interface postProps {
    token: string
    page: number
}

export const logGetOrderSendAsync = createAsyncThunk('user/log/deposit', async (data: postProps) => {
    const { apiPost } = useApi()
    const { token } = data
    return await apiPost({ ...data, token: '' }, `user/logs/deposit?access_token=${token}`)
})

export const LogDepositSlice = createSlice({
    name: 'user/logs/deposit',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logGetOrderSendAsync.pending, (state) => {
                return { ...state, message: '', status: 'loading', items: [] }
            })
            .addCase(logGetOrderSendAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as logsDepositState
                return { ...state, status: 'succeeded', items, message: '' }
            })
            .addCase(logGetOrderSendAsync.rejected, (state, { error }) => {
                return { ...state, message: error.message as string, status: 'failed', items: [] }
            })
    },
})
export const { clearState } = LogDepositSlice.actions
export const selectLogDeposit = (state: RootState) => state.logDeposit
export default LogDepositSlice.reducer
