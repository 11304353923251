import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

export interface itemProps {
    value: number
    createdAt: Date
    updatedAt: Date
    note: string
    dealerFullName: string
    dealerUsername: string
    img: string
    typeAmount: {
        type: boolean
        before: number
        after: number
    }
}

interface logHistoriesTradeState {
    items: Array<itemProps>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: logHistoriesTradeState = {
    items: [],
    message: '',
    status: 'idle',
}

interface postProps {
    token: string
    page: number
    value: number
}

export const logHistoriesTradeAsync = createAsyncThunk('user/log/histories-trade/by-order', async (data: postProps) => {
    const { apiPost } = useApi()
    const { token } = data
    return await apiPost({ ...data, token: '' }, `user/log/histories-trade/by-order?access_token=${token}`)
})

export const LogHistoriesTradeSlice = createSlice({
    name: 'user/logs/histories-trade',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logHistoriesTradeAsync.pending, (state) => {
                return { ...state, message: '', status: 'loading', items: [] }
            })
            .addCase(logHistoriesTradeAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as logHistoriesTradeState
                return { ...state, status: 'succeeded', items, message: '' }
            })
            .addCase(logHistoriesTradeAsync.rejected, (state, { error }) => {
                return { ...state, message: error.message as string, status: 'failed', items: [] }
            })
    },
})
export const { clearState } = LogHistoriesTradeSlice.actions
export const selectHistoriesTrade = (state: RootState) => state.logHistoriesTrade
export default LogHistoriesTradeSlice.reducer
