import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { informationState } from '../../User/LoginSlice'
interface typeAmoutProps {
    type: boolean
    before: number
    after: number
}
interface userProps {
    username: string
    phone: string
}
export interface itemProps {
    value: number
    information: informationState
    createdAt?: Date
    typeAmount: typeAmoutProps
    user: userProps
    comfrim: number
    _id: string
    vnd: string
}

interface getLogWithdrawState {
    items: Array<itemProps>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusConfrim: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: getLogWithdrawState = {
    items: [],
    message: '',
    status: 'idle',
    statusConfrim: 'idle',
}
interface getProps {
    token: string
    page: number
}

interface clickProps {
    token: string
    _id: string
    type: number
}

export const getLogWithdrawAsync = createAsyncThunk('admin/logs/withdraw/get', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`admin/get-log-withdraw/${page}?access_token=${token}`)
})

export const adminConfrimWithdrawByIdAsync = createAsyncThunk('admin/logs/withdraw/confirm', async (data: clickProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/logs/comfrim-withdraw-by-id?access_token=${data.token}`)
})
export const getLogWithdrawSlice = createSlice({
    name: 'admin/logs/withdraw',
    initialState,
    reducers: {
        clearLogWithdrawState: (state) => {
            return {
                ...state,
                status: 'idle',
                statusConfrim: 'idle',
                message: '',
                item: initialState.items,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogWithdrawAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogWithdrawState
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(getLogWithdrawAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogWithdrawAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })

            .addCase(adminConfrimWithdrawByIdAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusConfrim: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminConfrimWithdrawByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusConfrim: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminConfrimWithdrawByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusConfrim: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearLogWithdrawState } = getLogWithdrawSlice.actions
export const selectLogWithdraw = (state: RootState) => state.adminGetLogWithdraw
export default getLogWithdrawSlice.reducer
