import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { GetDealerItem } from '../../Dealer/DealerSlice'
import { CategoryItem } from '../../Category/GetCategorySlice'
import useApi from '../../../../hooks/useApi'

interface ProductItem {
    sale: number
    price: number
    name: string
    imgs: Array<string>
    view: 0
    slug: string
    actived: boolean
    sortText: string
    description: string
    dealer: GetDealerItem
    hotProduct: boolean
    categoryIds: Array<CategoryItem>
}
interface GetAdminEditProductctState {
    item: ProductItem
    firstCategory: CategoryItem
    categories: Array<CategoryItem>
    message: string
    curentChoiseCategory: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusChoiseCategory: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusDeleteCategory: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusOpenLockProduct: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusHotProduct: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusEditProfile: 'idle' | 'loading' | 'failed' | 'sucessded'
    slugEdit: string
    editProduct: boolean
}

const initialState: GetAdminEditProductctState = {
    item: {
        sale: 0,
        price: 0,
        name: '',
        imgs: [],
        view: 0,
        slug: '',
        sortText: '',
        actived: false,
        description: '',
        dealer: { name: '', slug: '', _id: '', img: '' },
        categoryIds: [],
        hotProduct: false,
    },
    firstCategory: { slug: '', img: '', name: '', _id: '' },
    categories: [],
    message: '',
    status: 'idle',
    statusChoiseCategory: 'idle',
    curentChoiseCategory: '',
    statusDeleteCategory: 'idle',
    statusOpenLockProduct: 'idle',
    statusHotProduct: 'idle',
    statusEditProfile: 'idle',
    editProduct: true,
    slugEdit: '',
}
interface getProps {
    token: string
    slug: string
}

interface postProps {
    token: string
    slug: string
    _id: string
}

interface propsAction {
    token: string
    slug: string
    type: boolean
}

interface propsEditProfileProduct {
    name: string
    price: number | string
    sale: number
    sortText: string
    description: string
    token: string
    captcha: number | string
    slug: string
}

export const adminEditPruductBySlugAsync = createAsyncThunk('admin/edit-product/edit-profile-product-by-slug', async (data: propsEditProfileProduct) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-profile-product-by-slug?access_token=${data.token}`)
})

export const adminGetProductBySlugAsync = createAsyncThunk('admin/edit-product/get-by-slug', async (data: getProps) => {
    const { token, slug } = data
    const { apiGet } = useApi()
    return await apiGet(`admin/find-product-by-slug/${slug}?access_token=${token}`)
})

export const adminSetProductCategoryBySlugAsync = createAsyncThunk('admin/edit-product/set-product-category-by-slug', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/set-product-category-by-slug?access_token=${data.token}`)
})

export const adminDeleteProductCategoryBySlugAsync = createAsyncThunk('admin/edit-product/delete-product-category-by-slug', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/delete-product-category-by-slug?access_token=${data.token}`)
})

export const adminOpenLockProductBySlugAsync = createAsyncThunk('admin/edit-product/open-lock-product-by-slug', async (data: propsAction) => {
    const { apiPost } = useApi()

    return await apiPost({ ...data, token: '' }, `admin/open-lock-product-by-slug/?access_token=${data.token}`)
})

export const adminSetHotProductBySlugAsync = createAsyncThunk('admin/edit-product/set-hot-product-by-slug', async (data: propsAction) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/set-hot-product-by-slug/?access_token=${data.token}`)
})

export const getAdminEditProducttSlice = createSlice({
    name: 'admin/edit-product',
    initialState,
    reducers: {
        clearState: () => initialState,
        setChoiseCategory: (state, { payload }) => {
            return { ...state, curentChoiseCategory: payload as string, statusChoiseCategory: 'idle' }
        },
        setEditProduct: (state, { payload }) => {
            return { ...state, editProduct: payload as boolean }
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(adminEditPruductBySlugAsync.fulfilled, (state, { payload }) => {
                return {
                    ...state,
                    slugEdit: payload as string,
                    statusEditProfile: 'sucessded',
                }
            })
            .addCase(adminEditPruductBySlugAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusEditProfile: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminEditPruductBySlugAsync.pending, (state) => {
                return {
                    ...state,
                    statusEditProfile: 'loading',
                }
            })
            // -------------------------------------------------------------------------------------------------------

            .addCase(adminGetProductBySlugAsync.fulfilled, (state, { payload }) => {
                const { categories, item, firstCategory } = payload as GetAdminEditProductctState
                return {
                    ...state,
                    status: 'sucessded',
                    item,
                    message: '',
                    firstCategory,
                    categories,
                }
            })
            .addCase(adminGetProductBySlugAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.item,
                    firstCategory: initialState.firstCategory,
                    categories: initialState.categories,
                }
            })
            .addCase(adminGetProductBySlugAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    item: initialState.item,
                    message: '',
                    firstCategory: initialState.firstCategory,
                    categories: initialState.categories,
                }
            })
            // -------------------------------------------------------------------------------------------------------
            .addCase(adminSetProductCategoryBySlugAsync.fulfilled, (state, { payload }) => {
                const { categories, item, firstCategory } = payload as GetAdminEditProductctState
                return {
                    ...state,
                    statusChoiseCategory: 'sucessded',
                    item,
                    message: '',
                    firstCategory,
                    categories,
                }
            })
            .addCase(adminSetProductCategoryBySlugAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusChoiseCategory: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminSetProductCategoryBySlugAsync.pending, (state) => {
                return {
                    ...state,
                    statusChoiseCategory: 'loading',
                }
            })
            // -------------------------------------------------------------------------------------------------------
            .addCase(adminDeleteProductCategoryBySlugAsync.fulfilled, (state, { payload }) => {
                const { categories, item, firstCategory } = payload as GetAdminEditProductctState
                return {
                    ...state,
                    statusDeleteCategory: 'sucessded',
                    item,
                    message: '',
                    firstCategory,
                    categories,
                }
            })
            .addCase(adminDeleteProductCategoryBySlugAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusDeleteCategory: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminDeleteProductCategoryBySlugAsync.pending, (state) => {
                return {
                    ...state,
                    statusDeleteCategory: 'loading',
                }
            })
            // -------------------------------------------------------------------------------------------------------
            .addCase(adminOpenLockProductBySlugAsync.fulfilled, (state, { payload }) => {
                const { categories, item, firstCategory } = payload as GetAdminEditProductctState
                return {
                    ...state,
                    statusOpenLockProduct: 'sucessded',
                    item,
                    message: '',
                    firstCategory,
                    categories,
                }
            })
            .addCase(adminOpenLockProductBySlugAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusOpenLockProduct: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminOpenLockProductBySlugAsync.pending, (state) => {
                return {
                    ...state,
                    statusOpenLockProduct: 'loading',
                }
            })

            // -------------------------------------------------------------------------------------------------------
            .addCase(adminSetHotProductBySlugAsync.fulfilled, (state, { payload }) => {
                const { categories, item, firstCategory } = payload as GetAdminEditProductctState
                return {
                    ...state,
                    statusHotProduct: 'sucessded',
                    item,
                    message: '',
                    firstCategory,
                    categories,
                }
            })
            .addCase(adminSetHotProductBySlugAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusHotProduct: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminSetHotProductBySlugAsync.pending, (state) => {
                return {
                    ...state,
                    statusHotProduct: 'loading',
                }
            })
    },
})

export const { clearState, setChoiseCategory, setEditProduct } = getAdminEditProducttSlice.actions
export const selectAdminEditProductct = (state: RootState) => state.adminEditPruduct
export default getAdminEditProducttSlice.reducer
