import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface tranferState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded' | 'socketSuccessed'
}

interface TranferProps {
    username: string
    amount: string | number
    token: string
    captcha: number | string
    type: number
    socketId: string
}

const initialState: tranferState = {
    message: '',
    status: 'idle',
}

export const TranferAsync = createAsyncThunk('user/tranfer/submit', async (data: TranferProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/tranfer?access_token=${data.token}`)
})

export const TranferSlice = createSlice({
    name: 'user/tranfer',
    initialState,
    reducers: {
        setSocketSuccessed: (state) => {
            return { ...state, status: 'socketSuccessed' }
        },

        clearState: (state) => {
            return { ...state, status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(TranferAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(TranferAsync.fulfilled, (state) => {
                return { ...state, status: 'succeeded' }
            })
            .addCase(TranferAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState, setSocketSuccessed } = TranferSlice.actions
export const selectTranfer = (state: RootState) => state.tranfer
export default TranferSlice.reducer
