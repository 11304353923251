import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface CoutryItem {
    code: number
    status: 'idle' | 'loading' | 'failed'
}

const initialState: CoutryItem = {
    code: 0,
    status: 'idle',
}

export const captchaSlice = createSlice({
    name: 'captcha',
    initialState,
    reducers: {
        setCaptchaCode: (state) => {
            return { ...state, code: Math.floor(Math.random() * 100000) + 1 }
        },
    },
})
export const { setCaptchaCode } = captchaSlice.actions
export const selectCaptcha = (state: RootState) => state.captcha.code
export default captchaSlice.reducer
