import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface AdminResetSystem {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: AdminResetSystem = {
    message: '',
    status: 'idle',
}
interface Props {
    token: string
}

export const adminResetSystem = createAsyncThunk('admin/reset/system/post', async (data: Props) => {
    const { apiGet } = useApi()
    return await apiGet(`admin/system/reset?access_token=${data.token}`)
})

export const AdminResetSlice = createSlice({
    name: 'admin/reset/system',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminResetSystem.fulfilled, (state) => {
                return {
                    ...state,
                    status: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminResetSystem.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminResetSystem.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearState } = AdminResetSlice.actions
export const selectAdminResetSystem = (state: RootState) => state.adminResetSystem
export default AdminResetSlice.reducer
