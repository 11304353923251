import React from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
    isDefault: boolean
    description?: string
    title?: string
    image?: string
    name?: string
}

const HelmetPage: React.FC<Props> = ({ isDefault, name }) => {
    //const { NODE_ENV } = process.env
    return (
        <>
            {isDefault ? (
                <Helmet>
                    <title>SHOP SKEY VIET</title>
                    {/*<meta name="description" content="Vô Vàn Hàng Chính Hãng, Luôn Cập Nhật Giá Tốt, Thanh Toán An Toàn, Mua Ngay! Giao Hàng Tận Nơi, Đảm Bảo Chất Lượng, Đổi Trả Vừa Ý." />
                    <meta property="og:description" content="Vô Vàn Hàng Chính Hãng, Luôn Cập Nhật Giá Tốt, Thanh Toán An Toàn, Mua Ngay! Giao Hàng Tận Nơi, Đảm Bảo Chất Lượng, Đổi Trả Vừa Ý." />
                    <meta name="twitter:description" content="Vô Vàn Hàng Chính Hãng, Luôn Cập Nhật Giá Tốt, Thanh Toán An Toàn, Mua Ngay! Giao Hàng Tận Nơi, Đảm Bảo Chất Lượng, Đổi Trả Vừa Ý." />

                    <meta name="title" content="Bách Hoá Vệt" />
                    <meta name="twitter:title" content="Bách Hoá Vệt" />
                    <meta property="og:title" content="Bách Hoá Vệt" />

                    <meta property="og:image" content="https://bachhoaviet.co/apple-touch-icon.png" />
                    <meta name="twitter:image" content="https://bachhoaviet.co/apple-touch-icon.png" />
                      */}
                </Helmet>
            ) : (
                <Helmet>
                    <title>E Shop Việt Nam - {name}</title>
                    {/*  <meta name="description" content={`Bách Hoá Việt ${description}`} />
                    <meta property="og:description" content={description} />
                    <meta name="twitter:description" content={description} />

                    <meta name="title" content={name} />
                    <meta name="twitter:title" content={title} />
                    <meta property="og:title" content={title} />

                    <meta property="og:image" content={image} />
                    <meta name="twitter:image" content={image} />
                  */}
                </Helmet>
            )}
        </>
    )
}
export default HelmetPage
