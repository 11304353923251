import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface LocaleState {
    locale: string
}
const initialState: LocaleState = {
    locale: 'vi',
}

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        changeLocale: (state, { payload }) => {
            ;(payload === 'en' || payload === 'ci' || payload === 'vi' || payload === 'ko' || payload === 'ja' || payload === 'fr') && localStorage.setItem('language', payload)
            state.locale = payload
        },
    },
})

export const selectLocal = (state: RootState) => state.locale.locale
export const { changeLocale } = localeSlice.actions
export default localeSlice.reducer
