import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface registerState {
    messages: string
    username: string
    phone: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface userRegisterState {
    phone: string
    password1: string
    conf_password1: string
    captcha: number | string
    refParent?: string
}

const initialState: registerState = {
    messages: '',
    username: '',
    phone: '',
    status: 'idle',
}

export const RegisterAsync = createAsyncThunk('user/register', async (data: userRegisterState) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data }, 'user/sign-up')
})

export const registerSlice = createSlice({
    name: 'user/register',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(RegisterAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as registerState) }
            })
            .addCase(RegisterAsync.fulfilled, (state, { payload }) => {
                const newState = { ...(payload as registerState), status: 'succeeded' }
                return { ...state, ...(newState as registerState) }
            })
            .addCase(RegisterAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', messages: error.message as string }
                return { ...state, ...(newState as registerState) }
            })
    },
})
export const { clearState } = registerSlice.actions
export const selectRigister = (state: RootState) => state.register
export default registerSlice.reducer
