const ThemeConfig = {
    typography: {
        fontFamily: ['Roboto Serif', 'sans-serif'].join(','),
        fontSize: 13,
        body2: {
            fontSize: '0.9285714285714286rem',
        },
        caption: { fontSize: '0.9285714285714286rem' },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'p',
                    body2: 'span',
                    inherit: 'span',
                },
            },
        },
    },
}

export default ThemeConfig
