import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
interface ConfirmCartState {
    status: 'idle' | 'loading' | 'failed' | 'success'
    message: string
}

const initialState: ConfirmCartState = {
    status: 'idle',
    message: '',
}

interface propsPost {
    ids: Array<string>
    token: string
    socketId: string
}

export const confirmCartAsync = createAsyncThunk('product/confirm-cart/post', async (data: propsPost) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `product/confirm-cart?access_token=${data.token}`)
})
export const ConfirmCartSlice = createSlice({
    name: 'product/confirm-cart',
    initialState,
    reducers: {
        successOrder: (state) => {
            return { ...state, status: 'success', message: '' }
        },

        errorOrder: (state, { payload }) => {
            return { ...state, status: 'failed', message: payload as string }
        },
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(confirmCartAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(confirmCartAsync.fulfilled, (state) => {
                return { ...state, status: 'loading', message: '' }
            })
            .addCase(confirmCartAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { successOrder, errorOrder, clearState } = ConfirmCartSlice.actions
export const selectConfirmCart = (state: RootState) => state.confirmCart
export default ConfirmCartSlice.reducer
