import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface removeSlideState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: removeSlideState = {
    message: '',
    status: 'idle',
}
interface postProps {
    token: string
    link: string
}

export const removeSlideAsync = createAsyncThunk('admin/slide/remove/post', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/remove-slide?access_token=${data.token}`)
})

export const removeSlideSlice = createSlice({
    name: 'admin/slide/remove',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(removeSlideAsync.pending, (state) => {
                return { ...state, status: 'loading', message: '' }
            })
            .addCase(removeSlideAsync.fulfilled, (state) => {
                return { ...state, status: 'sucessded', message: '' }
            })
            .addCase(removeSlideAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = removeSlideSlice.actions
export const selectRemoveSlide = (state: RootState) => state.RemoveSlideReducer
export default removeSlideSlice.reducer
