import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { deliveryAddressProps } from '../LoginSlice'

interface UploadDeliveryAddressState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    deliveryAddress: Array<deliveryAddressProps>
}

interface postPros {
    token: string
    captcha: number | string
    name: string
    phone: string
    city: string
    district: string
    ward: string
    address: string
}

interface postProsChangeDefault {
    token: string
    id: number
}
const initialState: UploadDeliveryAddressState = {
    message: '',
    status: 'idle',
    deliveryAddress: [],
}

export const UploadDeliveryAddressAsync = createAsyncThunk('user/upload-delivery-address/post', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/profile/delivery-address-update?access_token=${data.token}`)
})

export const ChangeDeliveryDefaultAddressAsync = createAsyncThunk('user/upload-delivery-address/change-default', async (data: postProsChangeDefault) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/profile/delivery-address-default?access_token=${data.token}`)
})

export const UpLoadDeliveryAddressSlice = createSlice({
    name: 'user/upload-delivery-address',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(UploadDeliveryAddressAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadDeliveryAddressState) }
            })
            .addCase(UploadDeliveryAddressAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', deliveryAddress: payload as Array<deliveryAddressProps> }
                return { ...state, ...(newState as UploadDeliveryAddressState) }
            })
            .addCase(UploadDeliveryAddressAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadDeliveryAddressState) }
            })

            //----------------- change delivery default state ----------------
            .addCase(ChangeDeliveryDefaultAddressAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadDeliveryAddressState) }
            })
            .addCase(ChangeDeliveryDefaultAddressAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', deliveryAddress: payload as Array<deliveryAddressProps> }
                return { ...state, ...(newState as UploadDeliveryAddressState) }
            })
            .addCase(ChangeDeliveryDefaultAddressAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadDeliveryAddressState) }
            })
    },
})

export const { clearState } = UpLoadDeliveryAddressSlice.actions
export const selectDeliveryAddress = (state: RootState) => state.deliveryAddress
export default UpLoadDeliveryAddressSlice.reducer
