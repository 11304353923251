import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface DictrictItem {
    name_with_type: string
    code: string
}

interface DictrictState {
    items: Array<DictrictItem>
    message: string
    status: 'idle' | 'loading' | 'failed'
}

interface getPros {
    token: string
    city: string
}

const initialState: DictrictState = {
    items: [],
    message: '',
    status: 'idle',
}

export const getDictrictAsync = createAsyncThunk('user/district/get', async (data: getPros) => {
    const { apiGet } = useApi()
    return await apiGet(`user/get-district?access_token=${data.token}&city=${data.city}`)
})

export const DictrictSlice = createSlice({
    name: 'user/district',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDictrictAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(getDictrictAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'idle', items: payload as Array<DictrictItem> }
            })
            .addCase(getDictrictAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})
export const selectDictrict = (state: RootState) => state.district
export default DictrictSlice.reducer
