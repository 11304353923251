import { ListItemButton, ListItemIcon, ListItemText, styled, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { useCallback } from 'react'
import NumberFormat from 'react-number-format'
import { Link, useHistory } from 'react-router-dom'
import LazyImage from '../../../component/LazyImage'
import { dealers } from '../../../states/features/Category/GetProduct'

interface menuPropsExtend {
    item: dealers
    index: number
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}

const StyledTextClamp = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 22px;
    width: 100%;
`
const ListItemButtonDealer = (props: menuPropsExtend) => {
    const { setOpenMenuLeft, item, index } = props
    const history = useHistory()
    const handleClick = useCallback(
        (link: string) => {
            history.push(link)
            setOpenMenuLeft((pre) => !pre)
        },
        [history, setOpenMenuLeft]
    )
    return (
        <>
            <ListItemButton component={Link} to={window.location.pathname + '?sh=' + item.slug} sx={{ display: { xs: 'none', md: 'flex' }, py: 0.2, mt: index === 0 ? 1 : 0 }}>
                <ListItemIcon sx={{ minWidth: '40px', minHeight: '44px', display: 'flex', alignItems: 'center' }}>
                    <LazyImage src={item.img} sx={{ width: '30px', height: '30px', borderRadius: '30px' }} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <StyledTextClamp variant="body2">
                                {item.name.charAt(0).toUpperCase()}
                                {item.name.slice(1).toLowerCase()}{' '}
                            </StyledTextClamp>

                            <NumberFormat
                                value={item.qty}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(formattedValue) => (
                                    <Typography variant="body2" color={red[900]} fontWeight={500}>
                                        ({formattedValue})
                                    </Typography>
                                )}
                            />
                        </Typography>
                    }
                />
            </ListItemButton>

            <ListItemButton
                sx={{ display: { xs: 'flex', md: 'none' }, py: 0.2, mt: index === 0 ? 1 : 0 }}
                onClick={() => {
                    handleClick(window.location.pathname + '?sh=' + item.slug)
                }}
            >
                <ListItemIcon sx={{ minWidth: '40px', minHeight: '44px', display: 'flex', alignItems: 'center' }}>
                    <LazyImage src={item.img} sx={{ width: '30px', height: '30px', borderRadius: '30px' }} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <StyledTextClamp variant="body2">
                                {item.name.charAt(0).toUpperCase()}
                                {item.name.slice(1).toLowerCase()}{' '}
                            </StyledTextClamp>

                            <NumberFormat
                                value={item.qty}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(formattedValue) => (
                                    <Typography variant="body2" color={red[900]} fontWeight={500}>
                                        ({formattedValue})
                                    </Typography>
                                )}
                            />
                        </Typography>
                    }
                />
            </ListItemButton>
        </>
    )
}

export default ListItemButtonDealer
