import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface depositState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded' | 'socketSuccessed'
}

interface TranferProps {
    amount: string | number
    token: string
    captcha: number | string
    socketId: string
}

const initialState: depositState = {
    message: '',
    status: 'idle',
}

export const DepositAsync = createAsyncThunk('user/deposit/submit', async (data: TranferProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/deposit?access_token=${data.token}`)
})

export const TranferSlice = createSlice({
    name: 'user/deposit',
    initialState,
    reducers: {
        setSocketSuccessed: (state) => {
            return { ...state, status: 'socketSuccessed' }
        },

        clearState: (state) => {
            return { ...state, status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(DepositAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(DepositAsync.fulfilled, (state) => {
                return { ...state, status: 'succeeded' }
            })
            .addCase(DepositAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState, setSocketSuccessed } = TranferSlice.actions
export const selectDeposit = (state: RootState) => state.deposit
export default TranferSlice.reducer
