import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from '../../hooks/Localization'
import { useAppDispatch, useAppSelector } from '../../states/app/hooks'
import { selectLogin as SelectUser, clearState as clearUser } from '../../states/features/User/LoginSlice'
import { grey, blue, red } from '@mui/material/colors'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Fab from '@mui/material/Fab'
import { RightButton, Cart, Account } from './components/RightButton'
import InputSearch from './components/InputSearch'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import LoginIcon from '@mui/icons-material/Login'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import UseSwitchesCustom from '../../component/ToggleTheme'
import { MdOutlineDashboard } from 'react-icons/md'
import { AiOutlineMenu, AiOutlineUser } from 'react-icons/ai'
import { VscSignOut } from 'react-icons/vsc'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import LazyImage from '../../component/LazyImage'
import { menuProps } from '../Menu'
import { FaRegHandshake } from 'react-icons/fa'
import { Container } from '@mui/material'

interface Props extends menuProps {
    window?: () => Window
}
function HideOnScroll(props: Props) {
    const { children, window } = props
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    })
    return (
        <Slide appear={true} in={!trigger}>
            {children}
        </Slide>
    )
}
const PageHome: React.FC<Props> = (props: Props) => {
    const t = useTranslation
    const dispatch = useAppDispatch()
    const { children } = props
    const { username, information } = useAppSelector(SelectUser)
    const { linkAvatar } = information
    const history = useHistory()
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const [anchorElUserAuth, setAnchorElUserAuth] = React.useState<null | HTMLElement>(null)

    const handleOpenUserMenuAuth = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUserAuth(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }
    const handleCloseUserMenu = (page: number) => {
        switch (page) {
            case 0:
                history.push('/dang-nhap.html')
                break
            case 1:
                history.push('/dang-ky-tai-khoan.html')
                break
        }

        setAnchorElUser(null)
    }

    const settings = [t('Login'), t('Register')]
    const settingsAuth = [t('Dashboard'), t('Profile'), 'Địa chỉ giao hàng', t('Logout')]

    const handleCloseUserMenuAuth = (page: number) => {
        switch (page) {
            case 0:
                history.push('/tong-hop.html')
                break
            case 1:
                history.push('/ho-so.html')
                break
            case 2:
                history.push('/dia-chi-giao-hang.html')
                break

            case 3:
                dispatch(clearUser())
                break
        }

        setAnchorElUserAuth(null)
    }

    const { pathname } = useLocation()
    useEffect(() => {
        const anchor = document.querySelector('#back-to-top-anchor')
        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }, [pathname])

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    position="relative"
                    enableColorOnDark={false}
                    sx={{
                        boxShadow: 'none',
                        borderBottom: (theme) => (theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)'),
                        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'rgb(39 38 44)' : 'rgb(255 255 255)'),
                        backgroundImage: 'none',
                    }}
                >
                    <Toolbar sx={{ paddingX: { xs: '0px' }, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between' }}>
                                <IconButton
                                    sx={{ display: { xs: 'flex', md: 'none' }, py: 0, px: 0 }}
                                    onClick={() => {
                                        props.setOpenMenuLeft(!props.openMenuLeft)
                                    }}
                                    color="success"
                                >
                                    <AiOutlineMenu color={blue[900]} />
                                </IconButton>
                                {/*
                                <Box component={Link} to="/" sx={{  display: { xs: 'none' }, textDecoration: 'none', color: 'inherit' }}>
                                    <LazyImage src="/android-chrome-192x192.png" width={70} alt={'Bách hoá việt'} sx={{ ml: 1 }} />
                                </Box> */}
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', width: 'calc(100% - 315px)' }, py: 0, px: 0 }}>
                                <InputSearch />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '0px !important' }}>
                                {username ? (
                                    <Box sx={{ flexGrow: 1 }}>
                                        <IconButton onClick={handleOpenUserMenuAuth}>
                                            {linkAvatar ? (
                                                <LazyImage sx={{ mr: 0.5, borderRadius: '100%' }} height="30px" width="30px" src={linkAvatar} alt={username} />
                                            ) : (
                                                <LazyImage sx={{ mr: 0.5 }} height="30px" width="30px" src="/images/home/avatar.png" alt={username} />
                                            )}

                                            <Typography sx={{ textTransform: 'capitalize', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : grey[800]) }}>{username}</Typography>
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorElUserAuth}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            open={Boolean(anchorElUserAuth)}
                                            onClose={handleCloseUserMenuAuth}
                                        >
                                            <Box>
                                                {settingsAuth.map((setting, index) => (
                                                    <MenuItem key={index} onClick={() => handleCloseUserMenuAuth(index)}>
                                                        {index === 0 ? <MdOutlineDashboard size={20} /> : index === 1 ? <AiOutlineUser size={20} /> : index === 2 ? <FaRegHandshake size={20} /> : <VscSignOut size={20} />}
                                                        <Typography sx={{ marginLeft: '10px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : grey[800]) }} textAlign="center">
                                                            {setting}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        </Menu>
                                    </Box>
                                ) : (
                                    <Box sx={{ flexGrow: 1 }}>
                                        <IconButton onClick={handleOpenUserMenu}>
                                            <LazyImage height="25px" width="25px" src="/images/home/avatar.png" alt={'Đăng nhập'} />
                                            <Typography color="white" sx={{ color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : grey[800]) }} ml={1}>
                                                {t('Account')}
                                            </Typography>
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            {settings.map((setting, index) => (
                                                <MenuItem key={setting} onClick={() => handleCloseUserMenu(index)}>
                                                    {index === 0 ? <LoginIcon fontSize="small" /> : <PersonAddAltIcon fontSize="small" />}
                                                    <Typography sx={{ marginLeft: '10px', color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : grey[800]) }} textAlign="center">
                                                        {setting}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                )}
                                <UseSwitchesCustom />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar id="back-to-top-anchor" sx={{ minHeight: '5px !important' }} />
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, paddingX: { md: '8px', xs: '5px' } }}>
                <InputSearch />
            </Box>

            <RightButton {...props}>
                <Fab color="default" size="small" aria-label="scroll back to top">
                    <ArrowUpwardIcon sx={{ color: red[900] }} />
                </Fab>
            </RightButton>

            <Cart {...props}>
                <Fab color="primary" size="small" aria-label="cart">
                    <AddShoppingCartIcon />
                </Fab>
            </Cart>

            <Account {...props}>
                <Fab size="small" aria-label="customer">
                    {linkAvatar ? <LazyImage sx={{ borderRadius: '100%' }} height="40px" width="40px" src={linkAvatar} alt={username} /> : <LazyImage height="40px" width="40px" src="/images/home/avatar.png" alt={username} />}
                </Fab>
            </Account>

            <Container maxWidth="xl" sx={{ px: 0, height: '100%' }}>
                {children}
            </Container>
        </>
    )
}
export default PageHome
