import { ListItemButton, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { menuProps } from '../config/UserMenu'

interface menuPropsExtend extends menuProps {
    currentAside: string
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}

const ListItemButtonExtend = (props: menuPropsExtend) => {
    const { href, icon, name, asideName, currentAside, setOpenMenuLeft } = props
    const history = useHistory()
    const handleClick = useCallback(
        (link: string) => {
            history.push(link)
            setOpenMenuLeft((pre) => !pre)
        },
        [history, setOpenMenuLeft]
    )
    return (
        <>
            <ListItemButton sx={{ display: { xs: 'none', md: 'flex' } }} component={Link} to={href}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <SvgIcon sx={{ color: asideName === currentAside ? blue[900] : 'inherit' }} component={icon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ fontWeight: asideName === currentAside ? '500' : '400', color: asideName === currentAside ? blue[900] : 'inherit' }}>{name}</Typography>} />
            </ListItemButton>
            <ListItemButton
                sx={{ display: { xs: 'flex', md: 'none' } }}
                onClick={() => {
                    handleClick(href)
                }}
            >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <SvgIcon sx={{ color: asideName === currentAside ? blue[900] : 'inherit' }} component={icon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ fontWeight: asideName === currentAside ? '500' : '400', color: asideName === currentAside ? blue[900] : 'inherit' }}>{name}</Typography>} />
            </ListItemButton>
        </>
    )
}

export default ListItemButtonExtend
