import { lazy, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import chooseLocale from './hooks/Localization'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { useAppSelector } from './states/app/hooks'
import { selectLocal } from './states/features/Locale/LocalSlice'
import ColorModeContext from './context/ColorMode'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from './routerHistory'
import PageLoader from './component/Loader/PayLoader'
import SuspenseWithChunkError from './component/SuspenseWithChunkError'
import Page from './views/Page'
import { SnackbarProvider } from 'notistack'
import { selectLogin } from './states/features/User/LoginSlice'
import { SocketContext, socket } from './context/Socket'
import './style/custom.css'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/vi'
import 'animate.css'
import useTheme from './hooks/UseTheme'
import useMode from './hooks/useMode'
import useBeforeUnload from './hooks/useBeforeUnload'
import useLoadUser from './hooks/userLoadUser'
import HelmetPage from './component/Page/HelmetPage'
//page
const NotFound = lazy(() => import('./views/NotFound'))

//user
const Register = lazy(() => import('./views/Customer/Register'))
const RegisterActive = lazy(() => import('./views/Customer/Register/components/Active'))
const Login = lazy(() => import('./views/Customer/Login'))
const ForgotPassword = lazy(() => import('./views/Customer/ForgotPassword'))
const DashboardOveriew = lazy(() => import('./views/Customer/Dashboard/Overview'))
const Profile = lazy(() => import('./views/Customer/Proflie'))
const StoreRegister = lazy(() => import('./views/Customer/StoreRegister'))
const Tree = lazy(() => import('./views/Customer/Tree'))
const Password = lazy(() => import('./views/Customer/Proflie/Password1'))
const HistorySendOrderWaiting = lazy(() => import('./views/Customer/Logs/HisoriesSendOrder/Waitting'))
const HistorySendOrderConfrim = lazy(() => import('./views/Customer/Logs/HisoriesSendOrder/Confrim'))
const HistorySendOrderCancel = lazy(() => import('./views/Customer/Logs/HisoriesSendOrder/Cancel'))
// const HistoryTradeBuyOrder = lazy(() => import('./views/Customer/Logs/HisoriesTrade/BuyOrder'))
const HistoriesTradeProfitOrder = lazy(() => import('./views/Customer/Logs/HisoriesTrade/ProfitOrder'))
const HistoriesTradeLinkStoreOrder = lazy(() => import('./views/Customer/Logs/HisoriesTrade/LinkStoreOrder'))
const Cart = lazy(() => import('./views/Cart'))
const Payment = lazy(() => import('./views/Customer/Payment'))
const DeleveryAddressAdd = lazy(() => import('./views/Customer/DeleveryAddress/AddDeliveryAddress'))
const DeleveryAddressShow = lazy(() => import('./views/Customer/DeleveryAddress/ShowDeliveryAddress'))
const ProductBuyed = lazy(() => import('./views/Customer/Logs/ProductBuyed'))
const ProductDetail = lazy(() => import('./views/Product/Detail'))
const TranferBah = lazy(() => import('./views/Customer/Tranfer/Bah'))
const TranferBahProduct = lazy(() => import('./views/Customer/Tranfer/BahProduct'))
// const Deposit = lazy(() => import('./views/Customer/Deposit'))
// const SystemDeposit = lazy(() => import('./views/Customer/SystemDeposit'))
const WithDraw = lazy(() => import('./views/Withdraw'))

//Page
const Home = lazy(() => import('./views/Home'))
const Category = lazy(() => import('./views/Category'))
const ListDealer = lazy(() => import('./views/Dealer/ListDealer'))

//Admin
const AdminOverview = lazy(() => import('./views/Admin/Overview'))
const AdminCategory = lazy(() => import('./views/Admin/Category'))
const AdminProduct = lazy(() => import('./views/Admin/Product'))
const AdminProductEdit = lazy(() => import('./views/Admin/Product/components/EditProduct'))
const AdminUser = lazy(() => import('./views/Admin/User'))
const AdminEditUser = lazy(() => import('./views/Admin/User/components/Edit/User'))
const AdminBank = lazy(() => import('./views/Admin/Bank'))
const AdminLogDepositPost = lazy(() => import('./views/Admin/Log/DepositPost'))
const AdminLogDeposit = lazy(() => import('./views/Admin/Log/Deposit'))
const AdminLogWithdraw = lazy(() => import('./views/Admin/Log/Withdraw'))
const AdminSlide = lazy(() => import('./views/Admin/Slide'))
// Dealer
const DearProductShow = lazy(() => import('./views/Dealer/Product/Show'))
const DearProductAdd = lazy(() => import('./views/Dealer/Product/Add'))
const DealerQrCode = lazy(() => import('./views/Dealer/QrCode'))
const DealerOrderWatting = lazy(() => import('./views/Dealer/Order/Watting'))
const DealerOrderConfrim = lazy(() => import('./views/Dealer/Order/Confrim'))
const DealerOrderCancel = lazy(() => import('./views/Dealer/Order/Cancel'))
const DealerLogAmount = lazy(() => import('./views/Dealer/Log'))
const App = () => {
    const locale = useAppSelector(selectLocal)
    const { colorMode, mode } = useMode()
    const theme = useTheme(mode)
    useBeforeUnload()
    useLoadUser()

    const { token, role } = useAppSelector(selectLogin)
    useEffect(() => {
        window.addEventListener(
            'beforeunload',
            (e) => {
                e.preventDefault()
                socket.disconnect()
            },
            false
        )
    }, [])
    return (
        <SocketContext.Provider value={socket}>
            <SnackbarProvider maxSnack={2}>
                <Router history={history}>
                    <IntlProvider locale={locale} messages={chooseLocale(locale)}>
                        <ColorModeContext.Provider value={colorMode}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Page>
                                    <SuspenseWithChunkError fallback={<PageLoader />}>
                                        <Switch>
                                            {/*Start Page*/}
                                            <Route path="/" exact>
                                                <HelmetPage isDefault={true} />
                                                <Home />
                                            </Route>

                                            <Route path="/danh-muc/:slug.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <Category />
                                            </Route>

                                            <Route path="/danh-sach-cua-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <ListDealer />
                                            </Route>

                                            <Route path="/gio-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <Cart isPayment={false} />
                                            </Route>
                                            {/*End Page*/}

                                            {/* Admin */}

                                            <Route path="/admin/quan-ly-slide.html" exact>
                                                {role.admin && token ? <AdminSlide /> : <Redirect to="/" />}
                                            </Route>

                                            <Route path="/admin/danh-sach-rut-tien.html" exact>
                                                {role.admin && token ? <AdminLogWithdraw /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/tai-khoan-ngan-hang.html" exact>
                                                {role.admin && token ? <AdminBank /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/thong-tin-he-thong.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.admin && token ? <AdminOverview /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/quan-ly-danh-muc.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <Redirect to="/admin/quan-ly-danh-muc/danh-sach-danh-muc.html" />
                                            </Route>
                                            <Route path="/admin/quan-ly-danh-muc/:type.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.admin && token ? <AdminCategory /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/quan-ly-san-pham.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.admin && token ? <AdminProduct /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/chinh-sua-san-pham/:slug.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.admin && token ? <AdminProductEdit /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/quan-ly-khach-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.admin && token ? <AdminUser /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/quan-ly-khach-hang/:_id.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.admin && token ? <AdminEditUser /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/danh-sach-nap-tien.html" exact>
                                                {role.admin && token ? <AdminLogDeposit /> : <Redirect to="/" />}
                                            </Route>
                                            {/* <Route path="/admin/danh-sach-rut-tien.html" exact>
                                                {role.admin && token ? <AdminLogWithdraw /> : <Redirect to="/" />}
                                            </Route> */}
                                            <Route path="/admin/nhat-ky-yeu-cau-nap-tien.html" exact>
                                                {role.admin && token ? <AdminLogDepositPost /> : <Redirect to="/" />}
                                            </Route>
                                            {/* End Admin */}

                                            {/* Dealer */}
                                            <Route path="/cua-hang/quan-ly-san-pham.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.dealer && token ? <DearProductShow /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/cua-hang/them-san-pham.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.dealer && token ? <DearProductAdd /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/cua-hang/quan-ly-don-hang/dang-doi-xac-nhan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.dealer && token ? <DealerOrderWatting /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/cua-hang/quan-ly-don-hang/da-xac-nhan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.dealer && token ? <DealerOrderConfrim /> : <Redirect to="/" />}
                                            </Route>

                                            <Route path="/cua-hang/quan-ly-don-hang/da-duy.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {role.dealer && token ? <DealerOrderCancel /> : <Redirect to="/" />}
                                            </Route>

                                            <Route path="/cua-hang/ma-cua-hang/:slug.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <DealerQrCode />}
                                            </Route>

                                            {/* End Dealer */}

                                            {/*Start User*/}
                                            {/* <Route path="/nap-tien.html" exact>
                                                {!token ? <Login /> : <Deposit />}
                                            </Route> */}
                                            {/* <Route path="/phat-trien-he-thong.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <SystemDeposit /> : <Login />}
                                            </Route> */}

                                            <Route path="/nhat-ky-so-du.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <DealerLogAmount /> : <Login />}
                                            </Route>

                                            <Route path="/dang-ky-tai-khoan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <Register />
                                            </Route>

                                            <Route path="/dang-ky-tai-khoan/kich-hoat/:id.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <RegisterActive />
                                            </Route>

                                            <Route path="/dang-nhap.html">
                                                <HelmetPage isDefault={true} />
                                                <Login />
                                            </Route>

                                            <Route path="/quen-mat-khau.html">
                                                <HelmetPage isDefault={true} />
                                                <ForgotPassword />
                                            </Route>

                                            <Route path="/san-pham/:slug.html" exact>
                                                <ProductDetail />
                                            </Route>
                                            <Route path="/hop-tac-ban-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <StoreRegister />}
                                            </Route>

                                            <Route path="/them-dia-chi-giao-hang.html" exact>
                                                {!token ? <Login /> : <DeleveryAddressAdd />}
                                            </Route>

                                            <Route path="/dia-chi-giao-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <DeleveryAddressShow />}
                                            </Route>

                                            <Route path="/tong-hop.html" exact>
                                                <HelmetPage isDefault={true} />
                                                <Redirect to="/tong-hop/thong-tin.html" />
                                            </Route>

                                            <Route path="/thanh-toan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <Payment />}
                                            </Route>
                                            <Route path="/tong-hop/thong-tin.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <DashboardOveriew />}
                                            </Route>

                                            <Route path="/tong-hop/cay-he-thong.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <Tree />}
                                            </Route>
                                            <Route path="/ho-so.html" exact>
                                                <Redirect to="/ho-so/thong-tai-khoan.html" />
                                            </Route>

                                            <Route path="/ho-so/thong-tai-khoan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <Profile />}
                                            </Route>
                                            <Route path="/ho-so/thay-doi-mat-khau.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <Password />}
                                            </Route>

                                            <Route path="/lich-su/gui-don/dang-doi-xac-nhan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <HistorySendOrderWaiting />}
                                            </Route>

                                            <Route path="/lich-su/gui-don/dang-doi-xac-nhan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <HistorySendOrderWaiting />}
                                            </Route>
                                            <Route path="/lich-su/gui-don/da-xac-nhan.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <HistorySendOrderConfrim /> : <Login />}
                                            </Route>

                                            <Route path="/lich-su/gui-don/da-huy.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <HistorySendOrderCancel /> : <Login />}
                                            </Route>

                                            <Route path="/lich-su-mua-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <ProductBuyed /> : <Login />}
                                            </Route>

                                            <Route path="/lich-su-giao-dich/mua-hang.html" exact>
                                                <Redirect to="/lich-su-giao-dich/lich-su-chuyen-nhan-bah.html" />
                                                {/*<HelmetPage isDefault={true} />
                                                {token ? <HistoryTradeBuyOrder /> : <Login />}
                                                  */}
                                            </Route>
                                            <Route path="/ho-so-tai-khoan.html" exact>
                                                <Redirect to="/ho-so/thong-tai-khoan.html" />
                                            </Route>

                                            <Route path="/lich-su-giao-dich/hoa-hong.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <HistoriesTradeProfitOrder /> : <Login />}
                                            </Route>
                                            <Route path="/lich-su-giao-dich/lich-su-chuyen-nhan-bah.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {token ? <HistoriesTradeLinkStoreOrder /> : <Login />}
                                            </Route>

                                            <Route path="/chuyen-cho-thanh-vien-khac.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <TranferBah />}
                                            </Route>

                                            <Route path="/chuyen-bah-mua-hang.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <TranferBahProduct />}
                                            </Route>

                                            {/* <Route path="/tich-luy-bah.html" exact>
                                                <HelmetPage isDefault={true} />
                                                {!token ? <Login /> : <Deposit />}
                                            </Route> */}

                                            <Route path="/rut-tien.html" exact>
                                                {!token ? <Login /> : <WithDraw />}
                                            </Route>

                                            {/*End User*/}

                                            {/*Start Page*/}
                                            <Route component={NotFound} />
                                            {/*End Page*/}
                                        </Switch>
                                    </SuspenseWithChunkError>
                                </Page>
                            </ThemeProvider>
                        </ColorModeContext.Provider>
                    </IntlProvider>
                </Router>
            </SnackbarProvider>
        </SocketContext.Provider>
    )
}

export default App
