import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface AddSlideState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: AddSlideState = {
    message: '',
    status: 'idle',
}
interface postProps {
    token: string
    captcha: string | number
    image: undefined | string | ArrayBuffer | null
    imageName: string
}

export const addSlideAsync = createAsyncThunk('admin/slide/add', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/slide-add?access_token=${data.token}`)
})

export const addSlideSlice = createSlice({
    name: 'admin/slide',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addSlideAsync.pending, (state) => {
                return { ...state, status: 'loading', message: '' }
            })
            .addCase(addSlideAsync.fulfilled, (state) => {
                return { ...state, status: 'sucessded', message: '' }
            })
            .addCase(addSlideAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = addSlideSlice.actions
export const selectAddSlide = (state: RootState) => state.addSlide
export default addSlideSlice.reducer
