import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
interface ReportState {
    data: any
    message: string | number
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface ReportGet {
    page: number
    fromDate: Date | null
    toDate: Date | null
    logName: string
    token: string
}

const initialState: ReportState = {
    data: [],
    message: '',
    status: 'idle',
}

export const ReportGetAsync = createAsyncThunk('user/report/getdata', async (data: ReportGet) => {
    const { apiGet } = useApi()
    const { page, token, fromDate, toDate, logName } = data
    let action = `user/get-report?access_token=${token}`
    action = `${action}&page=${page}&fromDate=${fromDate}&toDate=${toDate}&logName=${logName}`
    return await apiGet(action)
})

export const ReportSlice = createSlice({
    name: 'user/report',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ReportGetAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(ReportGetAsync.fulfilled, (state, actions: PayloadAction<any>) => {
                return { ...state, status: 'succeeded', data: actions.payload.items, message: actions.payload.message as number }
            })
            .addCase(ReportGetAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const selectReport = (state: RootState) => state.report
export default ReportSlice.reducer
