import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { orderProps } from '../Dealer/DealerOrderQrcodeSlice'

export interface order extends orderProps {
    slug: string
}

interface logsGetOrderSendState {
    items: Array<order>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: logsGetOrderSendState = {
    items: [],
    message: '',
    status: 'idle',
}

interface postProps {
    token: string
    page: number
    confrim: number
}

export const logGetOrderSendAsync = createAsyncThunk('user/log/order-send/watting', async (data: postProps) => {
    const { apiPost } = useApi()
    const { token } = data
    return await apiPost({ ...data, token: '' }, `user/logs/order-send?access_token=${token}`)
})

export const LogGetOrderSendSlice = createSlice({
    name: 'user/logs/order-send',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logGetOrderSendAsync.pending, (state) => {
                return { ...state, message: '', status: 'loading', items: [] }
            })
            .addCase(logGetOrderSendAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as logsGetOrderSendState
                return { ...state, status: 'succeeded', items, message: '' }
            })
            .addCase(logGetOrderSendAsync.rejected, (state, { error }) => {
                return { ...state, message: error.message as string, status: 'failed', items: [] }
            })
    },
})
export const { clearState } = LogGetOrderSendSlice.actions
export const selectLogsGetOrderSend = (state: RootState) => state.logsGetOrderSend
export default LogGetOrderSendSlice.reducer
