import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

export interface GetAdmimBankState {
    bankAccount: string
    bankName: string
    bankNumber: string
    message: string
    number: number
}
interface bankItemsState {
    items: Array<GetAdmimBankState>
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusEdit: 'idle' | 'loading' | 'failed' | 'sucessded'
    message: string
}

const initialState: bankItemsState = {
    items: [],
    status: 'idle',
    statusEdit: 'idle',
    message: '',
}
interface getProps {
    token: string
}

interface editPost {
    token: string
    bankAccount: string
    bankName: string
    bankNumber: string
    number: number
}

export const adminGeBankAsync = createAsyncThunk('admin/bank/get', async (data: getProps) => {
    const { token } = data
    const { apiGet } = useApi()
    return await apiGet(`admin/get-bank?access_token=${token}`)
})

export const adminUpdateBankAsync = createAsyncThunk('admin/bank/edit', async (data: editPost) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/bank/edit?access_token=${data.token}`)
})

export const adminBankSlice = createSlice({
    name: 'admin/bank',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            //Get info --------------------------------------------
            .addCase(adminGeBankAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as bankItemsState
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                }
            })
            .addCase(adminGeBankAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    items: [],
                }
            })
            .addCase(adminGeBankAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    items: [],
                }
            })

            .addCase(adminUpdateBankAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as bankItemsState
                return {
                    ...state,
                    statusEdit: 'sucessded',
                    message: 'Cập nhập thành công',
                    items,
                }
            })
            .addCase(adminUpdateBankAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusEdit: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdateBankAsync.pending, (state) => {
                return {
                    ...state,
                    statusEdit: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearState } = adminBankSlice.actions
export const selectAdminBank = (state: RootState) => state.adminBank
export default adminBankSlice.reducer
