import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface ToastState {
    show: boolean
}

const initialState: ToastState = {
    show: false,
}

export const backdropSlice = createSlice({
    name: 'user/backdrop',
    initialState,
    reducers: {
        eventLoading: (state, { payload }) => {
            return { ...state, show: payload as boolean }
        },
    },
})

export const { eventLoading } = backdropSlice.actions
export const selectBackdrop = (state: RootState) => state.backdrop
export default backdropSlice.reducer
