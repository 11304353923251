import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { informationState } from '../LoginSlice'

interface UploadBankState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
}

interface postPros {
    token: string
    captcha: number | string
    bankName: string
    bankBranch: string
    bankNumber: string
    bankAccount: string
}

const initialState: UploadBankState = {
    message: '',
    status: 'idle',
    information: {
        step: 0,
        fullname: '',
        passport: '',
        birthday: '',
        address: [],
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
        linkAvatar: '',
    },
}

export const UploadBankAsync = createAsyncThunk('user/upload-bank/bank', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/profile/bank-update?access_token=${data.token}`)
})

export const UpLoadBankSlice = createSlice({
    name: 'user/upload-bank',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UploadBankAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadBankState) }
            })
            .addCase(UploadBankAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', information: payload as informationState }
                return { ...state, ...(newState as UploadBankState) }
            })
            .addCase(UploadBankAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadBankState) }
            })
    },
})
export const selectUploadBank = (state: RootState) => state.uploadBank
export default UpLoadBankSlice.reducer
