import { useEffect } from 'react'
import { socket } from '../context/Socket'

const useBeforeUnload = () => {
    useEffect(() => {
        window.addEventListener(
            'beforeunload',
            (e) => {
                e.preventDefault()
                socket.disconnect()
            },
            false
        )
    }, [])
}

export default useBeforeUnload
