import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface asideItem {
    asideName: string
}

const initialState: asideItem = {
    asideName: 'Home',
}

export const asideSlice = createSlice({
    name: 'aside',
    initialState,
    reducers: {
        setAsideName: (state, { payload }) => {
            return { ...state, asideName: payload as string }
        },
    },
})
export const { setAsideName } = asideSlice.actions
export const selectAside = (state: RootState) => state.aisde
export default asideSlice.reducer
