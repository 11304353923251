import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface typeAmoutProps {
    type: boolean
    before: number
    after: number
}
export interface itemProps {
    value: number
    username: string
    createdAt?: Date
    typeAmount: typeAmoutProps
    vnd: string
    note?: string
}

interface getLogDepositState {
    items: Array<itemProps>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusDepositPost: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: getLogDepositState = {
    items: [],
    message: '',
    status: 'idle',
    statusDepositPost: 'idle',
}
interface getProps {
    token: string
    page: number
}

export const getLogDepositAsync = createAsyncThunk('admin/logs/deposit/get', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`admin/get-log-deposit/${page}?access_token=${token}`)
})

export const getLogDepositPostAsync = createAsyncThunk('admin/logs/deposit/post', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`admin/get-log-deposit-post/${page}?access_token=${token}`)
})
export const getLogDepositSlice = createSlice({
    name: 'admin/logs/deposit',
    initialState,
    reducers: {
        clearLogDepositState: (state) => {
            return {
                ...state,
                status: 'idle',
                statusDepositPost: 'idle',
                message: '',
                item: initialState.items,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogDepositAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogDepositState
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(getLogDepositAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogDepositAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })

            //)________________________________________________________________
            .addCase(getLogDepositPostAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogDepositState
                return {
                    ...state,
                    statusDepositPost: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(getLogDepositPostAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusDepositPost: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogDepositPostAsync.pending, (state) => {
                return {
                    ...state,
                    statusDepositPost: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })
    },
})

export const { clearLogDepositState } = getLogDepositSlice.actions
export const selectLogDeposit = (state: RootState) => state.adminGetLogDeposit
export default getLogDepositSlice.reducer
