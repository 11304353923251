import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface treeState {
    nodeId: string
    label: string
    hasChildren: boolean
    vip: boolean
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: treeState = {
    nodeId: '',
    label: '',
    hasChildren: false,
    vip: false,
    status: 'idle',
}

export const treeAsync = createAsyncThunk('tree/root', async (params: string) => {
    const { apiGet } = useApi()
    return await apiGet(params)
})
export const treeSlice = createSlice({
    name: 'tree',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(treeAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(treeAsync.fulfilled, (state, actions: PayloadAction<any>) => {
                return { ...state, status: 'succeeded', vip: actions.payload.vip, nodeId: actions.payload.nodeId, label: actions.payload.label, hasChildren: actions.payload.hasChildren }
            })
            .addCase(treeAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', messages: error.message }
            })
    },
})
export const selectTree = (state: RootState) => state.tree
export default treeSlice.reducer
