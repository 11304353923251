import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetProductItem } from './AddProduct'
interface PropsProduct extends GetProductItem {
    description: string
    sortText: string
    imgs: Array<string>
    phone: string
    proSame: Array<GetProductItem>
}
interface ProductDeatailState {
    item: Array<PropsProduct>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: ProductDeatailState = {
    item: [],
    message: '',
    status: 'idle',
}
interface getProps {
    slug: string
}

export const ProductDetailAsync = createAsyncThunk('product/detail/get', async (data: getProps) => {
    const { slug } = data
    const { apiGet } = useApi()
    return await apiGet(`product/detail/${slug}`)
})

export const ProductDetailSlice = createSlice({
    name: 'product/detail',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(ProductDetailAsync.pending, (state) => {
                return { ...state, status: 'loading', item: [] }
            })
            .addCase(ProductDetailAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as ProductDeatailState

                return { ...state, status: 'sucessded', message: '', item }
            })
            .addCase(ProductDetailAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string, item: [] }
            })
    },
})
export const { clearState } = ProductDetailSlice.actions
export const selectProductDetail = (state: RootState) => state.getProductDetail
export default ProductDetailSlice.reducer
