import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Divider, Typography } from '@mui/material'
// import ListItemIcon from '@mui/material/ListItemIcon'
// import List from '@mui/material/List'
// import ListItem from '@mui/material/ListItem'
// import ListItemButton from '@mui/material/ListItemButton'
// import ListItemText from '@mui/material/ListItemText'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { blue } from '@mui/material/colors'
import { useTranslation } from '../../../hooks/Localization'
// import { AccessTimeOutlined, MailOutline, PhoneOutlined } from '@mui/icons-material'

const Footer: React.FC = () => {
    const d = new Date()
    let year = d.getFullYear()
    return (
        <>
            <Divider sx={{ my: 3 }} />
            <Box sx={{ my: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography py={1} variant="h6" sx={{ textTransform: 'uppercase', fontSize: '18px !important' }} color={blue[900]}>
                            Thông tin liên hệ skeyviet.com
                        </Typography>

                        <Typography py={1} variant="body1">
                            Nội Hoàng, Yên Dũng, Bắc Giang
                        </Typography>
                        <Typography py={1} variant="body1">
                            Hotline: 0919 610 638
                        </Typography>
                        <Typography py={1} variant="body1">
                            Email: skeyviet@gmail.com
                        </Typography>
                        <Typography py={1} variant="body1">
                            MST/MSDN: 20J8 007 990
                        </Typography>
                    </Grid>
                    {/* 
                    <Grid item xs={12} md={2}>
                        <Typography py={1} variant="h6" sx={{ fontSize: '18px !important' }} color={blue[900]}>
                            THÔNG TIN
                        </Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ paddingX: '0px' }}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Giới thiệu" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ paddingX: '0px' }}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Liên hệ" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ paddingX: '0px' }}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Tin tức" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton sx={{ paddingX: '0px' }}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: '20px' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Tuyển dụng" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid> */}
                </Grid>
            </Box>
            <Box py={1} sx={{ textAlign: 'center', mt: 2 }}>
                <Divider sx={{ my: 3 }} />
                <Typography variant="body1">
                    COPYRIGHT © {year} {useTranslation('Webname')}
                </Typography>
            </Box>
        </>
    )
}
export default Footer
