import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface WardItem {
    name_with_type: string
    code: string
}

interface WardState {
    items: Array<WardItem>
    message: string
    status: 'idle' | 'loading' | 'failed'
}

interface getPros {
    token: string
    district: string
}

const initialState: WardState = {
    items: [],
    message: '',
    status: 'idle',
}

export const getWardAsync = createAsyncThunk('user/ward/get', async (data: getPros) => {
    const { apiGet } = useApi()
    return await apiGet(`user/get-ward?access_token=${data.token}&district=${data.district}`)
})

export const WardSlice = createSlice({
    name: 'user/ward',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWardAsync.pending, (state) => {
                return { ...state, status: 'loading', items: [] }
            })
            .addCase(getWardAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'idle', items: payload as Array<WardItem> }
            })
            .addCase(getWardAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', items: [], message: error.message as string }
            })
    },
})
export const selectWard = (state: RootState) => state.ward
export default WardSlice.reducer
