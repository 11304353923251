import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { blue } from '@mui/material/colors'
import { useCallback, createRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useOption from '../../../views/Category/hooks/useOption'
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    width: '100%',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing('11px', 1, 1, 0),
        paddingLeft: '30px',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    },
}))

const InputSearch = () => {
    const [se, setSe] = useState('')
    const history = useHistory()
    const { sh, so, ho } = useOption()
    const input = createRef()
    const handleSubmit = useCallback(
        (e: React.SyntheticEvent, input: any) => {
            e.preventDefault()
            if (!so && !sh && !sh) history.push(`/danh-muc/kham-pha-danh-muc.html?se=${se}`)
            else {
                const link = sh ? `?sh=${sh}&ho=${ho ? ho : 'mac-dinh'}&so=${so ? so : 'mac-dinh'}&se=${se}` : `?ho=${ho ? ho : 'mac-dinh'}&so=${so ? so : 'mac-dinh'}&se=${se}`
                history.push(`/danh-muc/kham-pha-danh-muc.html${link}`)
            }
            input.current.blur()
        },
        [history, so, sh, ho, se]
    )
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon sx={{ color: blue[900] }} />
            </SearchIconWrapper>
            <form onSubmit={(e) => handleSubmit(e, input)}>
                <StyledInputBase inputRef={input} value={se} onChange={(e) => setSe(e.target.value)} placeholder="Nhập sản phẩm cần tìm ...." inputProps={{ 'aria-label': 'search' }} />
            </form>
        </Search>
    )
}

export default InputSearch
