import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { GetDealerItem } from '../../Dealer/DealerSlice'
import { roleProps } from '../LoginSlice'

interface DealerProps extends GetDealerItem {
    actived: boolean
}
interface UploadStoreState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    dealer: DealerProps
    role: roleProps
}

interface postPros {
    token: string
    captcha: number | string
    image: undefined | string | ArrayBuffer | null
    imageName: string
    name: string
}
const initialState: UploadStoreState = {
    message: '',
    status: 'idle',
    dealer: {
        name: '',
        slug: '',
        img: '',
        _id: '',
        actived: false,
    },
    role: {
        customer: true,
        admin: false,
        dealer: false,
    },
}

export const UploadStoreAsync = createAsyncThunk('user/store/upload', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/store/upload?access_token=${data.token}`)
})

export const UpLoadStoreSlice = createSlice({
    name: 'user/store',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UploadStoreAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadStoreState) }
            })
            .addCase(UploadStoreAsync.fulfilled, (state, { payload }) => {
                const { dealer, role } = payload as UploadStoreState
                const newState = { ...initialState, status: 'succeeded', dealer, role }
                return { ...state, ...(newState as UploadStoreState) }
            })
            .addCase(UploadStoreAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadStoreState) }
            })
    },
})
export const selectUploadStore = (state: RootState) => state.userStoreUpload
export default UpLoadStoreSlice.reducer
