import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { informationState } from '../LoginSlice'

interface SystemJoinSliceState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
}

interface postPros {
    token: string
    number: number
    amount: number
}

const initialState: SystemJoinSliceState = {
    message: '',
    status: 'idle',
    information: {
        step: 0,
        fullname: '',
        passport: '',
        birthday: '',
        address: [],
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
        linkAvatar: '',
    },
}

export const SystemJoinSliceAsync = createAsyncThunk('user/system-join', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/system-join?access_token=${data.token}`)
})

export const SystemJoinSlice = createSlice({
    name: 'user/system-join',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(SystemJoinSliceAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as SystemJoinSliceState) }
            })
            .addCase(SystemJoinSliceAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', information: payload as informationState }
                return { ...state, ...(newState as SystemJoinSliceState) }
            })
            .addCase(SystemJoinSliceAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as SystemJoinSliceState) }
            })
    },
})
export const { clearState } = SystemJoinSlice.actions
export const selectSystemJoin = (state: RootState) => state.SysteJoinReducer
export default SystemJoinSlice.reducer
