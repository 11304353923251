import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import localReducer from '../features/Locale/LocalSlice'
import registerReducer from '../features/User/RegisterSlice'
import loginReducer from '../features/User/LoginSlice'
import captchaReducer from '../features/Captcha/CaptchaSlice'
import treeReducer from '../features/Tree/TreeSlice'
import asideReducer from '../features/Aside/AsideSlice'
import forgotPasswordReducer from '../features/User/ForgotPassword'
import forgotPasswordCodeReducer from '../features/User/ForgotPasswordCode'
import changePassword1Reducer from '../features/Passsword/Password1Silce'
import addressReducer from '../features/Wallet/AddressSlice'
import reportReducer from '../features/Report/ReportSlide'
import dashboardReducer from '../features/Dashboard/DashboardSlice'
import toastReducer from '../features/Toast/ToastSlice'
import backdropReducer from '../features/Backdrop/BackdropSlice'
import socketReducer from '../features/Socket/SocketSlice'
import acitiveReducer from '../features/User/ActiveSlice'
import uploadAvatarReducer from '../features/User/Upload/AvartaSlice'
import uploadInfoReduver from '../features/User/Upload/InfoSlice'
import cityReducer from '../features/City/CitySlice'
import districtReducer from '../features/District/DistrictSlice'
import wardReducer from '../features/Ward/WardSlice'
import uploadAddressReducer from '../features/User/Upload/AddressSlice'
import uploadBankReducer from '../features/User/Upload/BankSlice'
import getAllCategoryReducer from '../features/Category/GetCategorySlice'
import addCategoryReducer from '../features/Category/AddCategorySlice'
import getDealerHomeReducer from '../features/Dealer/DealerSlice'
import addProductReducer from '../features/Product/AddProduct'
import getProductDealerReducer from '../features/Dealer/GetProductSlice'
import getAdminFindPruductReucer from '../features/Admin/Product/FindProduct'
import admineditPruductReucer from '../features/Admin/Product/EditProduct'
import homeGetNewProductReducer from '../features/Product/GetProductNewHome'
import homeGetHotProductReducer from '../features/Product/GetProductHotHome'
import userStoreUploadReducer from '../features/User/Store/UploadSlice'
import adminGetAllUserReducer from '../features/Admin/User/ShowSlice'
import dealerQrocdeReducer from '../features/Dealer/DealerQrCode'
import adminEditUserReducer from '../features/Admin/User/EditUser'
import qrcodeInputPriceReducer from '../features/QrCode/InputPriceSlice'
import dealerGetOrderCodeReducer from '../features/Dealer/DealerOrderQrcodeSlice'
import logsGetOrderSendReducer from '../features/Logs/SendOrderSlice'
import logDealerAmountReducer from '../features/Dealer/DealerLogAmountSlice'
import logHistoriesTradeReducer from '../features/Logs/HistoriesTradeSlice'
import getListDealerReducer from '../features/ListsDeadler/ListDearlerSlice'
import getCategoryProductReducer from '../features/Category/GetProduct'
import addToCartReducer from '../features/Product/AddToCartSlice'
import deliveryAddressReducer from '../features/User/Upload/DeleveryAddressSlice'
import confirmCartReducer from '../features/Product/ConfirmCartSlice'
import getProductBuyedReducer from '../features/Logs/ProductBuyedSlice'
import getProductDetailReducer from '../features/Product/DetailSlice'
import adminGetSystemReducer from '../features/Admin/System/InfoSlice'
import adminResetSystemReducer from '../features/Admin/System/ResetSlice'
import tranferReducer from '../features/Tranfer/TranferSlice'
import depositReducer from '../features/Deposit/DepositSlice'
import logsDepositReducer from '../features/Logs/DepositSlice'
import SysteJoinReducer from '../features/User/SystemJoin/SystemJoinSlice'
import getLogDepositReducer from '../features/User/Log/DepositSlice'
import depositPostReducer from '../features/User/Deposit/DepositSlice'
import adminBankReducer from '../features/Admin/Bank/BankSlice'
import adminGetLogDepositReducer from '../features/Admin/Log/DepositSlice'
import adminGetLogWithdrawReducer from '../features/Admin/Log/WithdrawSlice'
import getLogWithdrawReducer from '../features/User/Log/WithdrawSlice'
import withdrawReducer from '../features/User/Withdraw/WithdrawSlice'
import SlideReducer from '../features/Admin/Slide/SldieSlice'
import GetSliceReducer from '../features/Admin/Slide/GetSlide'
import RemoveSlideReducer from '../features/Admin/Slide/RemoveSlide'
import VoucherReducer from '../features/Voucher/VoucherSlice'

const combinedReducer = combineReducers({
    locale: localReducer,
    register: registerReducer,
    login: loginReducer,
    captcha: captchaReducer,
    tree: treeReducer,
    aisde: asideReducer,
    forgotPassword: forgotPasswordReducer,
    forgotPasswordCode: forgotPasswordCodeReducer,
    changePassword1: changePassword1Reducer,
    address: addressReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    toast: toastReducer,
    backdrop: backdropReducer,
    socket: socketReducer,
    active: acitiveReducer,
    uploadAvatar: uploadAvatarReducer,
    uploadInfo: uploadInfoReduver,
    uploaddAddress: uploadAddressReducer,
    city: cityReducer,
    district: districtReducer,
    ward: wardReducer,
    uploadBank: uploadBankReducer,
    getAllcategory: getAllCategoryReducer,
    addCategory: addCategoryReducer,
    getDealerHome: getDealerHomeReducer,
    addProduct: addProductReducer,
    getProductDealer: getProductDealerReducer,
    getAdminFindPruduct: getAdminFindPruductReucer,
    adminEditPruduct: admineditPruductReucer,
    homeGetNewProduct: homeGetNewProductReducer,
    homeGetHotProduct: homeGetHotProductReducer,
    userStoreUpload: userStoreUploadReducer,
    adminGetAllUser: adminGetAllUserReducer,
    dealerQrocde: dealerQrocdeReducer,
    adminEditUser: adminEditUserReducer,
    qrcodeInputPrice: qrcodeInputPriceReducer,
    dealerGetOrderCode: dealerGetOrderCodeReducer,
    logsGetOrderSend: logsGetOrderSendReducer,
    logDealerAmount: logDealerAmountReducer,
    logHistoriesTrade: logHistoriesTradeReducer,
    getListDealer: getListDealerReducer,
    getCategoryProduct: getCategoryProductReducer,
    addToCart: addToCartReducer,
    deliveryAddress: deliveryAddressReducer,
    confirmCart: confirmCartReducer,
    getProductBuyed: getProductBuyedReducer,
    getProductDetail: getProductDetailReducer,
    adminGetSystem: adminGetSystemReducer,
    adminResetSystem: adminResetSystemReducer,
    tranfer: tranferReducer,
    deposit: depositReducer,
    logDeposit: logsDepositReducer,
    SysteJoinReducer,
    getLogDeposit: getLogDepositReducer,
    depositPost: depositPostReducer,
    adminBank: adminBankReducer,
    adminGetLogDeposit: adminGetLogDepositReducer,
    adminGetLogWithdraw: adminGetLogWithdrawReducer,
    getLogWithdraw: getLogWithdrawReducer,
    withdraw: withdrawReducer,
    addSlide: SlideReducer,
    selectGetSlide: GetSliceReducer,
    RemoveSlideReducer, VoucherReducer
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'user/loign/clearState' || action.type === 'user/login/loading/rejected') {
        localStorage.removeItem('token')
        window.location.reload()
    }
    return combinedReducer(state, action)
}
export const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()],
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
