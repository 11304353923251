import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface AddGetSlideState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    links: Array<link>
}

interface link {
    link: string
}

const initialState: AddGetSlideState = {
    message: '',
    status: 'idle',
    links: []
}


export const getSlideAsync = createAsyncThunk('admin/getslide/add', async () => {
    const { apiGet } = useApi()
    return await apiGet(`admin/getslide`)
})

export const addGetSlideSlice = createSlice({
    name: 'admin/setslide',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSlideAsync.pending, (state) => {
                return { ...state, status: 'loading', message: '' }
            })
            .addCase(getSlideAsync.fulfilled, (state, { payload }) => {
                const links = payload as Array<link>

                return { ...state, status: 'sucessded', message: '', links }
            })
            .addCase(getSlideAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = addGetSlideSlice.actions
export const selectGetSlide = (state: RootState) => state.selectGetSlide
export default addGetSlideSlice.reducer
