import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { informationState } from '../LoginSlice'

interface UploadAvatarState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
}

interface postPros {
    token: string
    captcha: number | string
    image: undefined | string | ArrayBuffer | null
    imageName: string
}
const initialState: UploadAvatarState = {
    message: '',
    status: 'idle',
    information: {
        step: 0,
        fullname: '',
        passport: '',
        birthday: '',
        address: [],
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
        linkAvatar: '',
    },
}

export const UploadAvatarAsync = createAsyncThunk('user/upload/avatar', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/profile/avatar-update?access_token=${data.token}`)
})

export const UpLoadAvatarSlice = createSlice({
    name: 'user/upload',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(UploadAvatarAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as UploadAvatarState) }
            })
            .addCase(UploadAvatarAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', information: payload as informationState }
                return { ...state, ...(newState as UploadAvatarState) }
            })
            .addCase(UploadAvatarAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as UploadAvatarState) }
            })
    },
})
export const selectUploadAvatar = (state: RootState) => state.uploadAvatar
export default UpLoadAvatarSlice.reducer
