import React, { useEffect, useState } from 'react'
import Toast from '../component/Toast'
import Backdrop from '../component/Backdrop'
import Page from '../component/Page'
import { useAppDispatch, useAppSelector } from '../states/app/hooks'
import { selectLogin } from '../states/features/User/LoginSlice'
import { eventLoading } from '../states/features/Backdrop/BackdropSlice'
import Menu from '../component/Menu'
import Footer from './Home/components/Footer'
const PageLayout: React.FC = ({ children }) => {
    const dispatch = useAppDispatch()
    const { status, statusLoading, token } = useAppSelector(selectLogin)
    const [openMenuLeft, setOpenMenuLeft] = useState(false)
    useEffect(() => {
        token && dispatch(eventLoading(!(status === 'succeeded' || statusLoading === 'succeeded')))
    }, [dispatch, status, statusLoading, token])
    return (
        <>
            <Menu openMenuLeft={openMenuLeft} setOpenMenuLeft={setOpenMenuLeft}>
                <Page openMenuLeft={openMenuLeft} setOpenMenuLeft={setOpenMenuLeft}>
                    <>
                        {children} <Footer />
                    </>
                </Page>
            </Menu>
            <Toast />
            <Backdrop />
        </>
    )
}

export default PageLayout
