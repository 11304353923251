import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'

interface addressState {
    message: string
    address: string
    idoAddress: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: addressState = {
    message: '',
    address: '',
    idoAddress: '',
    status: 'idle',
}

export const createAddressAsync = createAsyncThunk('address/create-new-address', async (params: string) => {
    const { apiGet } = useApi()
    return await apiGet(params)
})

export const createAddressIDOAsync = createAsyncThunk('address/create-new-address-bep20-ido', async (params: string) => {
    const { apiGet } = useApi()
    return await apiGet(params)
})

export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAddressAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(createAddressAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'succeeded', message: '', address: payload as string }
            })
            .addCase(createAddressAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })

            .addCase(createAddressIDOAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(createAddressIDOAsync.fulfilled, (state, { payload }) => {
                return { ...state, status: 'succeeded', message: '', idoAddress: payload as string }
            })
            .addCase(createAddressIDOAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})

export const { clearState } = addressSlice.actions
export const selectAddress = (state: RootState) => state.address
export default addressSlice.reducer
