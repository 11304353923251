import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { productProps } from '../Product/AddToCartSlice'
import { deliveryAddressProps } from '../User/LoginSlice'
export interface orderProps {
    value: number
    createdAt: Date
    updatedAt: Date
    _id: string
    note: string
    customerFullname: string
    customerUsername: string
    dealerFullname: string
    dealerUsername: string
    confrim: number
    img: string
    cartDelevery: {
        address: deliveryAddressProps
        products: Array<productProps>
    }
}

interface dealerGetOrderQrCodeState {
    items: Array<orderProps>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    statusPost: 'idle' | 'loading' | 'failed' | 'succeeded'
}

const initialState: dealerGetOrderQrCodeState = {
    items: [],
    message: '',
    status: 'idle',
    statusPost: 'idle',
}

interface getProps {
    token: string
    page: number
    confrim: number
}

interface postPros {
    token: string
    _id: string
    action: 'cancel' | 'confrim'
}

export const DealerGetOrderQrCoodeAsync = createAsyncThunk('dealer/order-manager/qr-code/get', async (data: getProps) => {
    const { apiPost } = useApi()
    const { token } = data
    return await apiPost({ ...data, token: '' }, `dealer/get-order-from-qr-code/?access_token=${token}`)
})

export const DealerActionOrderQrCoodeAsync = createAsyncThunk('dealer/order-manager/qr-code/action', async (data: postPros) => {
    const { apiPost } = useApi()
    const { token } = data
    return await apiPost({ ...data, token: '' }, `dealer/action-order-from-qr-code/?access_token=${token}`)
})
export const DealerOrderQrCodeSlice = createSlice({
    name: 'dealer/order-manager/qr-code',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', statusPost: 'idle', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            //Get-----------------------------------------------------------------------------------------------------------
            .addCase(DealerGetOrderQrCoodeAsync.pending, (state) => {
                return { ...state, message: '', status: 'loading', items: [] }
            })
            .addCase(DealerGetOrderQrCoodeAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as dealerGetOrderQrCodeState
                return { ...state, status: 'succeeded', items, message: '' }
            })
            .addCase(DealerGetOrderQrCoodeAsync.rejected, (state, { error }) => {
                return { ...state, message: error.message as string, status: 'failed', items: [] }
            })
            //Cancel-----------------------------------------------------------------------------------------------------------
            .addCase(DealerActionOrderQrCoodeAsync.pending, (state) => {
                return { ...state, message: '', statusPost: 'loading' }
            })
            .addCase(DealerActionOrderQrCoodeAsync.fulfilled, (state) => {
                return { ...state, statusPost: 'succeeded', message: '' }
            })
            .addCase(DealerActionOrderQrCoodeAsync.rejected, (state, { error }) => {
                return { ...state, message: error.message as string, statusPost: 'failed' }
            })
    },
})
export const { clearState } = DealerOrderQrCodeSlice.actions
export const selectDealerOrderQrCode = (state: RootState) => state.dealerGetOrderCode
export default DealerOrderQrCodeSlice.reducer
