import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/system/Box";
import { SxProps } from "@mui/material";
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholderImg?: string;
    errorImg?: string;
    sx?: SxProps
}
const LazyImage = ({ src, placeholderImg, errorImg, sx, ...props }: ImageProps) => {
    placeholderImg = typeof placeholderImg === 'undefined' ? '/loading.gif' : placeholderImg
    errorImg = typeof errorImg === 'undefined' ? '/no-image.webp' : errorImg

    const [imgSrc, setSrc] = useState(placeholderImg || src);

    const onLoad = useCallback(() => {
        setSrc(src);
    }, [src]);

    const onError = useCallback(() => {
        setSrc(errorImg || placeholderImg);
    }, [errorImg, placeholderImg]);

    useEffect(() => {
        const img = new Image();
        img.src = src as string;
        img.addEventListener("load", onLoad);
        img.addEventListener("error", onError);
        return () => {
            img.removeEventListener("load", onLoad);
            img.removeEventListener("error", onError);
        };
    }, [src, onLoad, onError]);

    return <Box component="img" {...props} src={imgSrc} sx={{ ...sx, maxHeight: '300px', maxWidth: '300px' }} />;
};

export default LazyImage
