import { createTheme, PaletteMode, responsiveFontSizes } from '@mui/material'
import { useMemo } from 'react'
import ThemeConfig from '../config/theme'

const useTheme = (mode: PaletteMode | undefined) => {
    const theme = useMemo(
        () =>
            createTheme({
                ...ThemeConfig,
                typography: { ...ThemeConfig.typography, button: { textTransform: 'none', fontSize: '1rem' } },
                palette: { mode, background: mode === 'dark' ? { default: 'rgb(39 38 44)', paper: 'rgb(39 38 44)' } : { default: 'rgb(255, 255, 255)', paper: 'rgb(255, 255, 255)' } },
            }),
        [mode]
    )
    return responsiveFontSizes(theme)
}
export default useTheme
