import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../hooks/useApi'
import { RootState } from '../../app/store'
import { GetProductItem } from './AddProduct'
interface dealerProps {
    img: string
    name: string
    slug: string
}
export interface productProps extends GetProductItem {
    qty: number
}
interface dealer {
    dealer: dealerProps
    products: Array<productProps>
}
interface AddToCartState {
    items: Array<string>
    dealers: Array<dealer>
    status: 'idle' | 'loading' | 'failed' | 'success'
    message: string
}

const initialState: AddToCartState = {
    items: [],
    dealers: [],
    status: 'idle',
    message: '',
}

interface propsPost {
    ids: Array<string>
}

export const getProductCartAsync = createAsyncThunk('product/add-to-cart/get', async (data: propsPost) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data }, `product/add-to-cart/get`)
})
export const AddToCartSlice = createSlice({
    name: 'product/add-to-cart',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle', items: [] }
        },
        addCart: (state, { payload }) => {
            const items = payload ? payload.split(',') : []
            return { ...state, status: 'idle', items }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductCartAsync.pending, (state) => {
                return { ...state, status: 'loading', dealers: [] }
            })
            .addCase(getProductCartAsync.fulfilled, (state, { payload }) => {
                const { dealers } = payload as AddToCartState
                return { ...state, status: 'success', message: '', dealers }
            })
            .addCase(getProductCartAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string, dealers: [] }
            })
    },
})
export const { clearState, addCart } = AddToCartSlice.actions
export const selectAddToCart = (state: RootState) => state.addToCart
export default AddToCartSlice.reducer
